import { ListViewsForWorkbookCommand, ListWorkbooksCommand, TableauClient } from '~/common';
import { Tenant } from '~/hooks/tenantProvider';
import { APIResponse } from '~/utils/network';

export interface MenuItemData {
  text: string;
  workBookId: string;
  viewId: string;
  path?: string;
  subMenuItems: MenuItemData[];
}

export const getMenuItems = async (
  tableau: TableauClient,
  tenant: Tenant,
): Promise<APIResponse<MenuItemData[]>> => {
  try {
    const listWorkbooks = new ListWorkbooksCommand({
      filter: `tags:eq:Portal,projectName:in:[${tenant.stage}-${tenant.name}-${tenant.id},${tenant.stage}-${tenant.industryName}-${tenant.industryId}-Shared]`,
    });
    const {
      workbooks: { workbook: workbooks = [] },
    } = await tableau.send(listWorkbooks);

    const promises = workbooks.map(async (workbook) => {
      const listViews = new ListViewsForWorkbookCommand({
        workbookId: workbook.id,
      });

      const {
        views: { view: views = [] },
      } = await tableau.send(listViews);

      return {
        text: workbook.name,
        workBookId: workbook.id,
        viewId: workbook.defaultViewId,
        path: workbook.contentUrl ?? '',
        subMenuItems: views.map((view) => {
          return {
            text: view.name,
            workBookId: workbook.id,
            viewId: view.id,
            path: view.contentUrl.replaceAll('sheets/', '') ?? '',
            subMenuItems: [],
          };
        }),
      };
    });

    const menuItems = await Promise.all(promises);

    return { data: menuItems, error: undefined };
  } catch (error: any) {
    return { error: error.message as string, data: undefined };
  }
};
