import React, { useMemo } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { UserGroups } from '~/common/types/UserGroups';
import { Api } from '~/common/utils/api';
import type { CognitoUser } from '@aws-amplify/auth';

export interface LochUser extends CognitoUser {
  attributes?: {
    [key: string]: string;
  };
}

export const getUserGroups = (user: LochUser | null | undefined): string[] => {
  return user?.getSignInUserSession()?.getIdToken()?.payload['cognito:groups'] ?? [];
};

export function getUserInGroup(user: LochUser | null | undefined, group: string): boolean {
  return getUserGroups(user).includes(group);
}
export function getUserInAdminUsers(user: LochUser | null | undefined): boolean {
  return getUserInGroup(user, UserGroups.Admin);
}

export function useUser() {
  const [user, setUser] = React.useState<LochUser | null>(null);

  // Load user on initial page load.
  React.useEffect(() => {
    async function load() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        // Api.setAuthHeaders(user);
        setUser(user);
      } catch (error) {
        setUser(null);
      }
    }

    load();
  }, []);

  // Refresh user.
  const refresh = React.useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      // Api.setAuthHeaders(user);
      setUser(user);
    } catch (error) {
      setUser(null);
    }
  }, []);

  // Perform logout actions.
  const logoutActions = React.useCallback(() => {
    // Nullify user.
    setUser(null);
    // Remove tableau items from local storage.
    const prefix = 'tableau.';
    const tableauKeys = Object.keys(localStorage).filter((key) => key.startsWith(prefix));
    tableauKeys.forEach((key) => localStorage.removeItem(key));
    Api.clearAuthorizationHeader();
  }, []);

  // Listen for auth events.
  React.useEffect(() => {
    const listenerCancelToken = Hub.listen('auth', (data) => {
      const action = {
        signIn: () => setUser(data.payload.data),
        autoSignIn: () => setUser(data.payload.data),

        signOut: logoutActions,
        autoSignIn_failure: logoutActions,
        userDeleted: logoutActions,
      }[data.payload.event];
      action?.();
    });

    // Stop listening.
    return () => {
      listenerCancelToken();
    };
  }, [logoutActions]);

  const isAdminUser = useMemo(() => getUserInAdminUsers(user), [user]);
  return { user, refresh, isAdminUser };
}
