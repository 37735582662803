import {
  PaginatedOutput,
  PathParameterInput,
  TableauCommand,
  stringifyPathParameters,
} from './command';

const METHOD_PATH = 'views';

export interface View {
  id: string;
  name: string;
  contentUrl: string;
  viewUrlName: string;
  workbook: { id: string };
  owner: { id: string };
  project: { id: string };
  tags: { tag?: { label: string }[] };
  usage?: {
    totalViewCount: string;
  };
}

export interface GetViewByPathCommandInput {
  workbookName: string;
  viewName: string;
}

export interface GetViewByPathCommandOutput {
  views: { view?: View[] };
}

export class GetViewByPathCommand
  implements TableauCommand<GetViewByPathCommandInput, GetViewByPathCommandOutput>
{
  method = 'GET';
  path: string;
  response?: GetViewByPathCommandOutput;

  constructor(input: GetViewByPathCommandInput) {
    this.path = `${METHOD_PATH}?filter=contentUrl:eq:${input.workbookName}/sheets/${input.viewName}`;
  }
}

export interface ListViewsCommandInput extends PathParameterInput {}

export interface ListViewsCommandOutput extends PaginatedOutput {
  views: { view?: View[] };
}

export class ListViewsCommand implements TableauCommand<undefined, ListViewsCommandOutput> {
  method = 'GET';
  path: string;
  response?: ListViewsCommandOutput;

  constructor(input?: ListViewsCommandInput) {
    const parameters = stringifyPathParameters(input);
    this.path = `${METHOD_PATH}${parameters}`;
  }
}
