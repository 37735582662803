import React from 'react';
import { Dialog } from '@headlessui/react';
import { ReactComponent as Close } from '~/images/close.svg';
import { ReactComponent as TableColumns } from '~/images/table-columns.svg';
import { ActiveView } from './activeView';
import { AddView } from './addView';
import { OriginalView } from './originalView';
import { View } from './view';
import type { CustomView } from '../tableau';
import type { CustomViewsActions } from '../toolbar';

import './index.scss';

export interface CustomViewsDialogProps {
  customViews: Array<CustomView>;
  activeCustomView: CustomView | undefined;
  actions: CustomViewsActions;
  open: boolean;
  onClose: () => void;
}

export function CustomViewsDialog({
  customViews,
  activeCustomView,
  actions,
  open,
  onClose,
}: CustomViewsDialogProps) {
  const existingViews = customViews.filter((view) => !view.shared);
  const sharedViews = customViews.filter((view) => view.shared);

  return (
    <Dialog id='custom-views-dialog' open={open} onClose={onClose}>
      <div className='outer'>
        <Dialog.Panel className='panel'>
          <Dialog.Title className='title'>
            <TableColumns className='shrink-0' width={16} />
            Custom Views
            <button className='ml-auto' onClick={onClose}>
              <Close width={12} />
            </button>
          </Dialog.Title>
          <div className='content'>
            {activeCustomView && (
              <ActiveView activeCustomView={activeCustomView} actions={actions} />
            )}
            <AddView actions={actions} />

            {existingViews.length > 0 && (
              <div>
                <h1>Existing Views</h1>
                {existingViews.map((view) => (
                  <View
                    key={view.url}
                    customView={view}
                    activeCustomView={activeCustomView}
                    actions={actions}
                  />
                ))}
              </div>
            )}
            <div>
              <h1>Shared Views</h1>
              {sharedViews.map((view) => (
                <View
                  key={view.url}
                  customView={view}
                  activeCustomView={activeCustomView}
                  actions={actions}
                />
              ))}
              <OriginalView activeCustomView={activeCustomView} actions={actions} />
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
