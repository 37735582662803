import React, { useEffect, useMemo } from 'react';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AuthenticatedRoutePath, PublicRoutePath } from '~/route-path';
import * as validation from '~/utils/validation';
import './form.scss';

type Inputs = {
  email: string;
  /// Confirmation
  confirmationCode: string;
  password: string;
  confirmPassword: string;
};

enum Mode {
  Reset,
  Confirm,
}

export function Reset() {
  const history = useHistory();
  const [error, setError] = React.useState('');
  const [mode, setMode] = React.useState(Mode.Reset);
  const search = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );
  const userId = search.get('userId');
  useEffect(() => {
    if (userId) {
      setMode(Mode.Confirm);
    }
  }, [history.location.search, search, userId]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      email: userId ?? '',
    },
    mode: 'onTouched',
  });

  const handleReset = handleSubmit(async (data) => {
    setError('');
    try {
      await Auth.forgotPassword(data.email);
      setMode(Mode.Confirm);
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Reset failed.';
      setError(`Unable to reset password: ${message}`);
    }
  });

  const handleConfirm = handleSubmit(async (data) => {
    setError('');
    try {
      await Auth.forgotPasswordSubmit(data.email, data.confirmationCode, data.password);
      await Auth.signIn(data.email, data.password);
      history.push(AuthenticatedRoutePath.Home);
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Reset failed.';
      setError(`Unable to confirm password reset: ${message}`);
    }
  });

  const handleResendCode = async () => {
    setError('');
    try {
      const email = getValues('email');
      if (!email || errors.email) return;
      await Auth.forgotPassword(email);
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Resend failed.';
      setError(`Unable to resend code: ${message}`);
    }
  };

  return (
    <>
      {mode === Mode.Reset && (
        <form id='authenticator-form' onSubmit={handleReset}>
          <h1>Reset Password</h1>
          <input
            {...register('email', { required: 'Email is required.' })}
            placeholder='Email'
            autoComplete='email'
          />
          {errors.email && <div className='error'>{errors.email.message}</div>}
          {error && <div className='error'>{error}</div>}
          <div className='actions'>
            <input className='theme-main' type='submit' value='Reset Password' />
            <input
              className='theme-inverse outlined'
              type='button'
              value='Login'
              onClick={() => history.push(PublicRoutePath.Login)}
            />
          </div>
        </form>
      )}
      {mode === Mode.Confirm && (
        <form id='authenticator-form' onSubmit={handleConfirm}>
          <h1>Confirm Password Reset</h1>
          <input
            {...register('email', { required: 'Email is required.' })}
            placeholder='Email'
            autoComplete='email'
          />
          {errors.email && <div className='error'>{errors.email.message}</div>}
          <input
            {...register('confirmationCode', { required: 'Confirmation code is required.' })}
            placeholder='Confirmation Code'
            autoComplete='one-time-code'
          />
          {errors.confirmationCode && (
            <div className='error'>{errors.confirmationCode.message}</div>
          )}
          <input
            {...register('password', {
              required: 'Password is required.',
              pattern: {
                value: validation.password,
                message:
                  'Password must be at least 8 characters long with at least one lowercase letter, uppercase letter, special character, and number.',
              },
            })}
            placeholder='New Password'
            type='password'
            autoComplete='new-password'
          />
          {errors.password && <div className='error'>{errors.password.message}</div>}
          <input
            {...register('confirmPassword', {
              validate: (value, inputs) => value === inputs.password || 'Passwords must match.',
            })}
            placeholder='Confirm Password'
            type='password'
            autoComplete='new-password'
          />
          {errors.confirmPassword && <div className='error'>{errors.confirmPassword.message}</div>}
          {error && <div className='error'>{error}</div>}
          <div className='actions'>
            <input className='theme-main' type='submit' value='Confirm Password Reset' />
            <input
              className='theme-inverse outlined'
              type='button'
              value='Resend Code'
              onClick={handleResendCode}
            />
          </div>
          <div className='secondary-actions'>
            <input
              className='color-main text'
              type='button'
              value='Login'
              onClick={() => history.push(PublicRoutePath.Login)}
            />
          </div>
        </form>
      )}
    </>
  );
}
