import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import * as amplify from 'aws-amplify';
import { useForm } from 'react-hook-form';
import PasswordUpdateDialog from '~/components/PasswordUpdateDialog';
import { useUser } from '~/hooks/useUser';
import * as validation from '~/utils/validation';

import './styles.scss';

interface User {
  givenName: string;
  familyName: string;
  email: string;
}

export default function Profile() {
  const { user, refresh } = useUser();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<User>({
    defaultValues: {
      givenName: '',
      familyName: '',
      email: '',
    },
    values: {
      givenName: user?.attributes?.given_name ?? '',
      familyName: user?.attributes?.family_name ?? '',
      email: user?.getUsername() ?? '',
    },
    mode: 'onTouched',
  });
  // const [error, setError] = React.useState('');

  const handleUpdateProfile = handleSubmit(async (data) => {
    const user = await amplify.Auth.currentAuthenticatedUser();
    const newAttributes = {
      given_name: data.givenName,
      family_name: data.familyName,
    };

    await amplify.Auth.updateUserAttributes(user, newAttributes);
    refresh();
    reset({ givenName: data.givenName, familyName: data.familyName, email: data.email });
    return;
  });

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '95%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 1,
      }}>
      <Box
        sx={{
          width: {
            xs: '100%',
            lg: '75%',
            xl: '50%',
          },
          minWidth: {
            xs: '550px',
          },
        }}>
        <form onSubmit={handleUpdateProfile}>
          <Card sx={{ m: 3, height: '100%' }}>
            <CardHeader
              avatar={<ContactEmergencyIcon />}
              title='My Profile'
              action={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    minHeight: '55px',
                    m: 'auto',
                  }}>
                  <PasswordUpdateDialog />
                </Box>
              }
            />
            <CardContent>
              <Grid container spacing={2} gap={3} sx={{ p: 2 }}>
                <Grid item xs={12}>
                  <Typography variant='h6' className='profile-label' sx={{ pb: 2, pl: 1 }}>
                    First Name
                  </Typography>
                  <TextField
                    {...register('givenName', {
                      required: 'First Name is required.',
                    })}
                    fullWidth
                    autoComplete='given-name'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h6' className='profile-label' sx={{ pb: 2, pl: 1 }}>
                    Last Name
                  </Typography>
                  {errors.givenName && <div className='error'>{errors.givenName.message}</div>}

                  <TextField
                    {...register('familyName', {
                      required: 'Last Name is required.',
                    })}
                    fullWidth
                    autoComplete='family-name'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h6' className='profile-label' sx={{ pb: 2, pl: 1 }}>
                    Email Address
                  </Typography>
                  {errors.familyName && <div className='error'>{errors.familyName.message}</div>}
                  <TextField
                    {...register('email', {
                      required: 'Email is required.',
                      pattern: { value: validation.email, message: 'Invalid email.' },
                    })}
                    autoComplete='email'
                    fullWidth
                    disabled
                  />
                </Grid>
                {errors.email && <div className='error'>{errors.email.message}</div>}
              </Grid>
            </CardContent>
            <CardActions style={{ justifyContent: 'end' }}>
              {isDirty ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pr: 2,
                  }}>
                  <IconButton type='submit' style={{ marginRight: 5 }}>
                    <CheckIcon />
                  </IconButton>
                  <IconButton color='error' onClick={() => reset()}>
                    <CancelIcon />
                  </IconButton>
                </Box>
              ) : (
                <Box sx={{ minHeight: '49px' }} />
              )}
            </CardActions>
          </Card>
        </form>
      </Box>
    </Box>
  );
}
