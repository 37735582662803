import { SecretsManagerClient, GetSecretValueCommand } from '@aws-sdk/client-secrets-manager';
import axios from 'axios';
import { throwError } from '../../utils/throw-error';
import { ITableauCredential, TableauAuthenticationResponse } from './models/tableau-credentials';

let secretsManager: SecretsManagerClient;

export class PersonalAccessToken implements ITableauCredential {
  static async fromAwsSecretsManager(): Promise<PersonalAccessToken> {
    const secretArn =
      process.env.TABLEAU_ADMIN_USER_SECRET_ARN ??
      throwError(
        new Error(
          'Invalid configuration: TABLEAU_ADMIN_USER_SECRET_ARN is required but was not provided.',
        ),
      );

    // Get the Tableau Connected App Config from Secrets Manager
    if (!secretsManager) {
      secretsManager = new SecretsManagerClient({});
    }
    const command = new GetSecretValueCommand({ SecretId: secretArn });
    const response = await secretsManager.send(command);
    const secret = response.SecretString ?? '';
    const { personalAccessTokenName, personalAccessTokenSecret } = JSON.parse(secret);

    return new PersonalAccessToken(personalAccessTokenName, personalAccessTokenSecret);
  }

  constructor(
    /**
     * The name of the Personal Access Token provisioned in Tableau.
     */
    public readonly personalAccessTokenName: string,
    /**
     * The value of the Personal Access Token provisioned in Tableau.
     */
    public readonly personalAccessTokenSecret: string,
  ) {}

  authenticate = async (endpoint: string, contentUrl: string) => {
    console.debug('Authenticating with Tableau using a Personal Access Token', {
      personalAccessTokenName: this.personalAccessTokenName,
      contentUrl,
      endpoint,
    });

    const response = await axios.post<TableauAuthenticationResponse>(`${endpoint}/auth/signin`, {
      credentials: {
        site: {
          contentUrl,
        },
        personalAccessTokenName: this.personalAccessTokenName,
        personalAccessTokenSecret: this.personalAccessTokenSecret,
      },
    });

    return response.data;
  };
}
