import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Menu from '~/components/Menu';
import Home from '~/pages/Home';
import NotFound from '~/pages/NotFound';
import Profile from '~/pages/Profile';
import Tenant from '~/pages/Tenant';
import TenantUsage from '~/pages/Tenant/usage';
import WorkbookView from '~/pages/Workbook/View/index';
import { AuthenticatedRoutePath, ProtectedRoutePath, RoutePath } from '~/route-path';
import colors from '~/sass/colors.scss';
import ProtectedRoute from '../Authentication/ProtectedRoute';
import Comments from '../Comments';

import './styles.scss';

function AuthenticatedWrapper(): JSX.Element {
  const initialBackgroundColor = colors.rootBackground;
  const [backgroundColor, setBackgroundColor] = React.useState(initialBackgroundColor);

  const main = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => main.current?.focus({ preventScroll: true }), []);
  const [displayComments, setCommentVisibility] = React.useState(false);
  const onWorkbookLoaded = (canDisplayComment: boolean): void => {
    setCommentVisibility(canDisplayComment);
  };

  const loadBackgroundColor = (backgroundColor: string): void => {
    setBackgroundColor(backgroundColor ? `#${backgroundColor}` : initialBackgroundColor);
  };

  return (
    <div id='authenticated-wrapper'>
      <aside className='menu-content'>
        <Menu />
      </aside>
      <main className='authenticated-content' ref={main} tabIndex={-1}>
        <Switch>
          <Route path={RoutePath.Root} exact>
            <Home />
          </Route>
          <Route path={AuthenticatedRoutePath.Home} exact>
            <Home />
          </Route>
          <Route path={AuthenticatedRoutePath.WorkbookView}>
            <WorkbookView
              onWorkbookLoaded={onWorkbookLoaded}
              loadBackground={loadBackgroundColor}
            />
          </Route>
          <ProtectedRoute path={ProtectedRoutePath.Tenant}>
            <Tenant />
          </ProtectedRoute>
          <ProtectedRoute path={ProtectedRoutePath.TenantUsage}>
            <TenantUsage />
          </ProtectedRoute>
          <Route path={AuthenticatedRoutePath.Profile}>
            <Profile />
          </Route>
          <Route path={RoutePath.All}>
            <NotFound />
          </Route>
        </Switch>
      </main>
      {displayComments && (
        <aside className='comment-content' style={{ backgroundColor }}>
          <Comments />
        </aside>
      )}
    </div>
  );
}

export default AuthenticatedWrapper;
