import { Auth } from 'aws-amplify';
import axios from 'axios';

export interface APIResponse<T> {
  data: T | undefined;
  error: string | undefined;
}
interface GenericAsyncFunction<T> {
  (): Promise<T>;
}

export async function genericAsyncAction<T>(
  action: GenericAsyncFunction<T>,
): Promise<APIResponse<T>> {
  try {
    const data = await action();
    return { data, error: undefined };
  } catch (e) {
    const error = e instanceof Error ? e.message : String(e);
    return { error, data: undefined };
  }
}

export async function fetchUsingGet<T>(
  address: string,
  parameters: Record<string, string> | null = null,
): Promise<APIResponse<T>> {
  return genericAsyncAction<T>(async () => {
    const token = await getIdToken();

    let url = address;
    if (parameters !== null) {
      const queryParams = new URLSearchParams(parameters).toString();
      url = `${address}?${queryParams}`;
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  });
}

export async function fetchUsingPost<T>(
  address: string,
  body: BodyInit | null = null,
): Promise<APIResponse<T>> {
  return genericAsyncAction<T>(async () => {
    const token = await getIdToken();

    const response = await axios.post(address, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  });
}

// Converts an object into Record<string, string> for use as GET parameters
// Example:
//   function test() {
//     const noConversionNeeded = { hey: "yo" };
//     const conversionNeeded = { just: 6 };
//     const x = fetchUsingGet<string>("addy", noConversionNeeded);
//     const y = fetchUsingGet<string>("addy", toStringRecord(conversionNeeded));
//   }
export function toStringRecord(
  parameters: Record<string, string | number | boolean>,
): Record<string, string> {
  const output = {} as Record<string, string>;

  Object.keys(parameters).forEach((key: string) => {
    output[key] = parameters[key].toString();
  });

  return output;
}

/**
 * Get id token from session.
 */
async function getIdToken(): Promise<string> {
  const currentSession = await Auth.currentSession();
  const idToken = currentSession.getIdToken();
  return idToken.getJwtToken();
}
