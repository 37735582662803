export class RoutePath {
  public static Root = '/';
  public static All = '*';

  // match a string with any path class members
  public static matchAny(path: string): boolean {
    const pathClassKeys = Object.keys(this);
    for (let i = 0; i < pathClassKeys.length; i++) {
      const pathClassKey = pathClassKeys[i];
      const pathClassValue = this[pathClassKey as keyof typeof RoutePath];
      if (RoutePath.match(path, pathClassValue as string)) {
        return true;
      }
    }
    return false;
  }

  // match a string with a path pattern
  public static match(path: string, pattern: string): boolean {
    const regex = new RegExp(
      pattern.replace(/:\w+/g, '([^/]+)').replace(/\*/g, '(.*)').replace(/\//g, '\\/'),
    );
    return regex.test(path);
  }
}

export class PublicRoutePath extends RoutePath {
  public static Login = '/login';
  public static Register = '/register';
  public static Recover = '/recover';
}

export class AuthenticatedRoutePath extends RoutePath {
  public static Home = '/home';
  public static WorkbookView = '/workbook/:workbook/view/:view';
  public static Profile = '/profile';
}

export class ProtectedRoutePath extends AuthenticatedRoutePath {
  public static Tenant = '/tenant';
  public static TenantUsage = '/tenant-usage';
}
