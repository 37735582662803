import axios from 'axios';
import { ApiCommand } from './api-command';

export interface ApiClientOptions {
  endpoint: string;
  token?: string;
}

export class ApiClient {
  private baseUrl = '';
  private token = '';
  constructor(options: ApiClientOptions) {
    this.baseUrl = options.endpoint;
    this.token = options.token ?? '';
  }

  public async send<InputType extends object, OutputType>(
    command: ApiCommand<InputType, OutputType>,
  ) {
    console.debug(`Sending request to ${this.baseUrl}/${command.path}...`);

    try {
      const response = await axios({
        method: command.method,
        url: `${this.baseUrl}/${command.path}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
        data: command.data,
      });

      return response.data as OutputType;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const err = {
          code: error.code,
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data,
        };
        console.error('[ApiClient::send] Error:', err);
      } else {
        console.error('[ApiClient::send] Error:', error);
      }
      throw error;
    }
  }
}
