import { GetViewPreviewImageCommand, ListViewsCommand, TableauClient } from '~/common';
import { Tenant } from '~/hooks/tenantProvider';
import { APIResponse } from '~/utils/network';

// Contracts
export interface Thumbnail {
  data: string; // base64 encoded image data
}

export interface View {
  id: string;
  name: string;
  viewUri: string;
  workbookId: string;
  workbookUri: string;
}

// Methods
export const getViews = async (
  tableau: TableauClient,
  tenant: Tenant,
): Promise<APIResponse<View[]>> => {
  try {
    const tenantGroup = `${tenant.stage}-${tenant.name}-${tenant.id}`;
    const sharedGroup = `${tenant.stage}-${tenant.industryName}-${tenant.industryId}-Shared`;

    const response = await tableau.send(
      new ListViewsCommand({
        filter: `tags:eq:Landing,projectName:in:[${tenantGroup},${sharedGroup}]`,
      }),
    );
    const views = response.views.view ?? [];
    const data = views.map((view) => ({
      id: view.id,
      name: view.name,
      viewUri: view.viewUrlName,
      workbookId: view.workbook.id,
      workbookUri: view.contentUrl.split('/', 1)[0],
    }));

    return { data, error: undefined };
  } catch (error: any) {
    return { error: error.message as string, data: undefined };
  }
};

export const getViewThumbnail = async (
  tableau: TableauClient,
  workbookId: string,
  viewId: string,
): Promise<APIResponse<Thumbnail>> => {
  const getThumbnail = new GetViewPreviewImageCommand({
    workbookId,
    viewId,
  });

  try {
    const response = await tableau.send(getThumbnail);

    // return image data from image/png blob response
    return {
      data: { data: URL.createObjectURL(response) },
      error: undefined,
    };
  } catch (error: any) {
    return { error: error.message as string, data: undefined };
  }
};
