import React from 'react';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTenant } from '~/hooks/tenantProvider';
import { AuthenticatedRoutePath, PublicRoutePath } from '~/route-path';
import * as validation from '~/utils/validation';
import { Loading } from './loading';
import './form.scss';

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  /// Confirmation
  confirmationCode: string;
};

enum Mode {
  Register,
  Confirm,
}

export function Register() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({ mode: 'onTouched' });
  const history = useHistory();
  const { id: tenantId, name: tenantName } = useTenant();
  const [error, setError] = React.useState('');
  const [mode, setMode] = React.useState(Mode.Register);
  const [isRegistering, setIsRegistering] = React.useState(false);

  const handleRegister = handleSubmit(async (data) => {
    setError('');
    try {
      await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          given_name: data.firstName,
          family_name: data.lastName,
          'custom:tenant': tenantId,
          'custom:tenantName': tenantName,
        },
      });
      setMode(Mode.Confirm);
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Registration failed.';
      setError(`Unable to register: ${message}`);
    }
  });

  const handleConfirm = handleSubmit(async (data) => {
    setError('');
    setIsRegistering(true);
    try {
      await Auth.confirmSignUp(data.email, data.confirmationCode);
      await Auth.signIn(data.email, data.password);
      history.push(AuthenticatedRoutePath.Home);
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Confirmation failed.';
      setError(`Unable to confirm user: ${message}`);
    } finally {
      setIsRegistering(false);
    }
  });

  const handleResendCode = async () => {
    setError('');
    try {
      const email = getValues('email');
      if (!email || errors.email) return;
      await Auth.resendSignUp(email);
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Resend failed.';
      setError(`Unable to resend code: ${message}`);
    }
  };

  if (isRegistering) {
    return <Loading />;
  }

  return (
    <>
      {mode === Mode.Register && (
        <form id='authenticator-form' onSubmit={handleRegister}>
          <h1>Register</h1>
          <input
            {...register('firstName', {
              required: 'First Name is required.',
            })}
            placeholder='First Name'
            autoComplete='given-name'
          />
          {errors.firstName && <div className='error'>{errors.firstName.message}</div>}
          <input
            {...register('lastName', {
              required: 'Last Name is required.',
            })}
            placeholder='Last Name'
            autoComplete='family-name'
          />
          {errors.lastName && <div className='error'>{errors.lastName.message}</div>}
          <input
            {...register('email', {
              required: 'Email is required.',
              pattern: { value: validation.email, message: 'Invalid email.' },
            })}
            placeholder='Email'
            autoComplete='email'
          />
          {errors.email && <div className='error'>{errors.email.message}</div>}
          <input
            {...register('password', {
              required: 'Password is required.',
              pattern: {
                value: validation.password,
                message:
                  'Password must be at least 8 characters long with at least one lowercase letter, uppercase letter, special character, and number.',
              },
            })}
            placeholder='Password'
            type='password'
            autoComplete='new-password'
          />
          {errors.password && <div className='error'>{errors.password.message}</div>}
          <input
            {...register('confirmPassword', {
              validate: (value, inputs) => value === inputs.password || 'Passwords must match.',
            })}
            placeholder='Confirm Password'
            type='password'
            autoComplete='new-password'
          />
          {errors.confirmPassword && <div className='error'>{errors.confirmPassword.message}</div>}
          {error && <div className='error'>{error}</div>}
          <div className='actions'>
            <input className='theme-main' type='submit' value='Register' />
            <input
              className='theme-inverse outlined'
              type='button'
              value='Login'
              onClick={() => history.push(PublicRoutePath.Login)}
            />
          </div>
        </form>
      )}
      {mode === Mode.Confirm && (
        <form id='authenticator-form' onSubmit={handleConfirm}>
          <h1>Confirm Email</h1>
          <input
            {...register('email', {
              required: 'Email is required.',
              pattern: { value: validation.email, message: 'Invalid email.' },
            })}
            placeholder='Email'
            autoComplete='email'
          />
          {errors.email && <div className='error'>{errors.email.message}</div>}
          <input
            {...register('confirmationCode', { required: 'Confirmation code is required.' })}
            placeholder='Confirmation Code'
            autoComplete='one-time-code'
          />
          {errors.confirmationCode && (
            <div className='error'>{errors.confirmationCode.message}</div>
          )}
          {error && <div className='error'>{error}</div>}
          <div className='actions'>
            <input className='theme-main' type='submit' value='Confirm' />
            <input
              className='theme-inverse outlined'
              type='button'
              value='Resend Code'
              onClick={handleResendCode}
            />
          </div>
          <div className='secondary-actions'>
            <input
              className='color-main text'
              type='button'
              value='Login'
              onClick={() => history.push(PublicRoutePath.Login)}
            />
          </div>
        </form>
      )}
    </>
  );
}
