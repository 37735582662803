import * as jwt from 'jose';
import { ITableauToken } from '~/common/tableau/models/tableau-token';
import { fetchUsingGet } from '~/utils/network';

export class ConnectedAppToken implements ITableauToken {
  private storedToken: string | null = null;

  constructor(
    /** Connected app endpoint.  */
    private readonly endpoint: string,
    /** Optional storage key specifier.  */
    private readonly specifier?: string,
  ) {}

  /**
   * Get the connected app token, refreshing if necessary.
   */
  token = async () => {
    this.storedToken = await this.loadToken();
    return this.storedToken;
  };

  private loadToken = async () => {
    // Get stored token.
    const token = this.storedToken ?? localStorage.getItem(this.keyAppToken());

    // Fetch new token if no stored token.
    if (!token) {
      return await this.fetchToken();
    }

    try {
      const decoded = jwt.decodeJwt(token);

      // Refresh expired token.
      if (!decoded.exp || decoded.exp < Date.now() / 1000) {
        return await this.fetchToken();
      }
    } catch (error) {
      // Refresh invalid token.
      console.error('Error decoding tableau token:', error);
      return await this.fetchToken();
    }

    // Return valid stored token.
    return token;
  };

  private fetchToken = async (): Promise<string | null> => {
    // Use standard auth for the request.
    const url = `${this.endpoint}/tableau/token`;
    const response = await fetchUsingGet<{ token: string }>(url);
    const token = response.data?.token;

    if (response.error || !token) {
      console.error('Error loading tableau token:', response.error);
      // Remove the token from local storage.
      localStorage.removeItem(this.keyAppToken());
      return null;
    }

    // Set the token in local storage.
    localStorage.setItem(this.keyAppToken(), token);
    return token;
  };

  private keyAppToken = () => this.key('tableau.appToken', this.specifier);
  private key = (key: string, id?: string) => (id ? `${key}.${id}` : key);
}
