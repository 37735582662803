import { useConfig } from '@kikoda/generated-config-hooks';
import axios from 'axios';
import { Api } from '~/common/utils/api';
import { WebConfig } from '~/web-config';
import { useUser } from '../useUser';

export interface AddUserRequest {
  userId?: string;
  givenName?: string;
  familyName?: string;
}

async function addUser(token: any, apiEndpoint: string, user: AddUserRequest): Promise<void> {
  if (!token) return;
  try {
    const api = axios.create();
    await api.post(
      `${apiEndpoint}/portal/users`,
      {
        user,
      },
      {
        headers: {
          Authorization: token,
        },
      },
    );
  } catch (error) {
    console.debug('Error deleting user', error);
  } finally {
    return;
  }
}

export const useAddTenantUser = () => {
  const config = useConfig<WebConfig>();
  const { user } = useUser();
  const { tenantServiceEndpoint } = config?.additionalConfig?.platform?.backend;

  const addTenantUser = async (newUser: AddUserRequest) => {
    await addUser(Api.getToken(user), tenantServiceEndpoint, newUser);
  };

  return { addTenantUser };
};
