import { useEffect, useState } from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import {
  ApiClient,
  DeleteCommentCommand,
  LikeCommentCommand,
  UnlikeCommentCommand,
} from '~/common';
import { useApi } from '~/hooks/apiProvider';
import { useUser } from '~/hooks/useUser';
import { ReactComponent as Delete } from '~/images/delete.svg';
import { ReactComponent as ThumbsUpAlt } from '~/images/thumbs-up-alt.svg';
import { ReactComponent as ThumbsUp } from '~/images/thumbs-up.svg';
import { ConfirmDialog } from '../Elements/ConfirmDialog';
import './comment.scss';

export interface CommentItemProps {
  tenandId: string;
  viewId: string;
  user?: {
    id: string;
    name: string;
  };
  text: string;
  datePosted: Date;
  isEdited: boolean;
  id?: string;
  likes: LikeComment[];
  sendData(props: any): any;
  commentDeleted(id?: string): any;
}

export interface LikeComment {
  userId: string;
  name: string;
}

export default function CommentItem(props: CommentItemProps): JSX.Element {
  const apiClient = useApi() as ApiClient;
  const { user } = useUser();
  const [comment, setComment] = useState(props);
  const [commentOwner, setCommentOwner] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

  useEffect(() => {
    setComment(props);
    setCommentOwner(comment.user?.id === user?.attributes?.sub);
  }, [comment.user?.id, props, user?.attributes?.sub]);

  const onClick = () => {
    props.sendData(props);
  };

  const likeUnlikeOnClick = async () => {
    let response;
    if (user && comment.likes?.filter((x) => x.userId === user?.attributes?.sub).length > 0) {
      const apiCommand = new UnlikeCommentCommand({
        viewId: comment.viewId,
        commentId: comment.id ?? '',
      });
      response = await apiClient.send(apiCommand);
    } else {
      const apiCommand = new LikeCommentCommand({
        viewId: comment.viewId,
        commentId: comment.id ?? '',
      });
      response = await apiClient.send(apiCommand);
    }
    const item: CommentItemProps = {
      datePosted: new Date(response.datePosted),
      isEdited: response.isEdited,
      likes: response.likes,
      sendData: comment.sendData,
      tenandId: comment.tenandId,
      text: response.text,
      viewId: response.viewId,
      id: comment.id,
      user: response.commentUser,
      commentDeleted: comment.commentDeleted,
    };
    setComment(item);
  };

  const getCommentInitials = (): string => {
    return comment?.user?.name?.match(/\b(\w)/g)?.join('') ?? '';
  };

  const getTooltipText = (): string => {
    if (!comment.likes || comment.likes.length === 0) {
      return '';
    } else {
      let names: string[] = comment.likes.map((x) => {
        return x.name;
      });

      if (comment.likes.length > 3) {
        names = comment.likes.slice(0, 3).map((x) => {
          return x.name;
        });
        names.push(`plus ${comment.likes.length - names.length} more likes`);
      }

      return names.join(', ');
    }
  };

  const deleteComment = async () => {
    const deleteCommentCommand = new DeleteCommentCommand({
      commentId: comment.id ?? '',
      viewId: comment.viewId,
    });

    await apiClient.send(deleteCommentCommand);

    comment.commentDeleted(comment.id);
    reset();
  };

  const reset = () => {
    setConfirmDeleteDialogOpen(false);
  };

  return (
    <li id='comment-item'>
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap={0}>
        <Box gridColumn='span 2'>
          <div className='avatar'> {getCommentInitials()}</div>
        </Box>
        <Box gridColumn='span 10' className='comment-box'>
          <Grid container id='header' onClick={onClick}>
            <Box
              className='view-comment'
              display='grid'
              gridTemplateColumns='repeat(12, 1fr)'
              gap={2}>
              <Box gridColumn='span 6'>
                <span className='username-text'>{`${comment.user?.name} `}</span>
              </Box>
              <Box gridColumn='span 1'>
                <span className='comment-date-text'>commented</span>
              </Box>
              <Box gridColumn='span 5' className='comment-date-text'>
                {comment.datePosted.toLocaleString('default', { month: 'long' })}{' '}
                {comment.datePosted.getDate()} at {comment.datePosted.toLocaleTimeString()}
              </Box>
            </Box>
          </Grid>
          <Grid container id='body' className='body-box' onClick={onClick}>
            <span className='comment-text'>{comment.text}</span>
          </Grid>
          <Grid container id='footer' className='footer-box'>
            <Tooltip title={getTooltipText()}>
              {user &&
              comment.likes?.filter((x) => x.userId === user?.attributes?.sub).length > 0 ? (
                <ThumbsUp
                  width={20}
                  className='liked '
                  onClick={async () => {
                    await likeUnlikeOnClick();
                  }}
                />
              ) : (
                <ThumbsUpAlt
                  width={20}
                  className='liked '
                  onClick={async () => {
                    await likeUnlikeOnClick();
                  }}
                />
              )}
            </Tooltip>
            {commentOwner && (
              <Delete
                width={10}
                className='liked delete'
                onClick={async () => {
                  setConfirmDeleteDialogOpen(true);
                  // await deleteComment();
                }}
              />
            )}
            <ConfirmDialog
              isOpen={confirmDeleteDialogOpen}
              title='Comment Deletion'
              message='The comment will be deleted. Do you wish to proceed?'
              onConfirm={deleteComment}
              onDeny={reset}
            />
          </Grid>
        </Box>
      </Box>
    </li>
  );
}
