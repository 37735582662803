import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTableau } from '~/hooks/tableauProvider';
import { getViewThumbnail, View } from './api';

export interface ViewThumbProps {
  view: View | undefined;
}

export default function ViewThumb({ view }: ViewThumbProps): JSX.Element {
  const tableauClient = useTableau();
  const [imageData, setImageData] = useState('');

  useEffect(() => {
    const getImageData = async () => {
      // Wait for tableauClient to load.
      if (!tableauClient) return;

      // Wait for view to load.
      if (!view) return;

      const { workbookId, id } = view;
      const { data, error } = await getViewThumbnail(tableauClient, workbookId, id);

      if (error) {
        console.error(error);
        toast(error);
      }

      setImageData(data?.data ?? '');
    };

    getImageData();
  }, [view, tableauClient]);

  return !view || imageData === '' ? (
    <Skeleton className='rounded' variant='rectangular' height={356} />
  ) : (
    <Link to={`workbook/${view.workbookUri}/view/${view.viewUri}`} className='container h-[356px]'>
      <p className='name'>{view.name}</p>
      <img className='rounded' src={imageData} alt={view.name} height={280} />
      <div className='overlay'>
        <div className='view-dashboard'>View Dashboard</div>
      </div>
    </Link>
  );
}
