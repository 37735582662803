import React from 'react';
import loading from '~/images/animated-nessi.gif';
import './form.scss';

const texts = ['Gathering data...', 'Transforming data...', 'Analyzing data...'];

export function Loading() {
  const [index, setIndex] = React.useState(0);
  const [text, setText] = React.useState(texts.at(index));

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      const i = index + 1;
      if (i < texts.length) {
        setText(texts.at(i));
        setIndex(i);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [index]);

  return (
    <div id='authenticator-form'>
      <h1>{text}</h1>
      <img className='splash' src={loading} alt='Loading...' />
    </div>
  );
}
