import {
  PaginatedOutput,
  PathParameterInput,
  TableauCommand,
  stringifyPathParameters,
} from './command';

const METHOD_PATH = 'connected-applications';

export interface ConnectedApp {
  /**
   * Name of the connected app.
   */
  name: string;

  /**
   * Whether the connected app is enabled or not.
   */
  enabled: boolean;

  /**
   * The ID of the connected app.
   */
  clientId: string;

  /**
   * The ID of the project that the connected app's access level is scoped to.
   */
  projectId: string;

  /**
   * The date and time the connected app was created.
   * The date and time are in ISO 8601 format and Coordinated Universal Time (UTC).
   *
   * @example '2020-01-01T00:00:00Z'
   */
  createdAt: string;

  /**
   * Whether the connected app can be hosted on all domains. If false is returned, the
   * `domainSafelist` attribute determines the domain access.
   */
  unrestrictedEmbedding: boolean;

  /**
   * A list of domains the connected app is allowed to be hosted. One or more URLs,
   * separated by spaces.
   *
   * @see https://help.tableau.com/current/online/en-us/connected_apps.htm#domain_rules
   */
  domainSafeList?: string;

  /**
   * Information related to the secret only if it has been previously generated.
   */
  secret?: {
    id: string;
    createdAt: string;
  };
}

export interface CreateConnectedAppCommandInput {
  connectedApplication: {
    /**
     * The name of the connected app.
     */
    name: string;

    /**
     * Controls whether the connected app is enabled or not. Value can be "true" or "false".
     * If the state is not specified, the connected app is set to "false" and not enabled by default.
     *
     * @default false
     */
    enabled?: boolean;

    /**
     * The ID of the project that the connected app's access level is scoped to. To get the project ID, see Query Projects.
     * To scope the connected app's access to all projects on a site, omit the projectId attribute from the request.
     *
     * @default - all projects
     */
    projectId?: string;

    /**
     * A list of domains the connected app is allowed to be hosted. Specify one or more URLs,
     * separated by spaces, using a format described in Domain allowlist rules in the Tableau Cloud Help.
     *
     * @see https://help.tableau.com/current/online/en-us/connected_apps.htm#domain_rules
     * @example 'https://tableau.com https://myco.com:552 marketing.example.com'
     * @default - all domains
     */
    domainSafelist?: string;

    /**
     * Controls whether the connected app can be hosted on all domains. If "true" value is specified,
     * the connected app can be hosted on all domains. If "false" value is specified, the connected app
     * can only be hosted on the domains specified in the `domainSafelist` attribute.
     *
     * @default true
     */
    unrestrictedEmbedding?: boolean;
  };
}

export interface CreateConnectedAppCommandOutput {
  connectedApplication: ConnectedApp;
}

export class CreateConnectedAppCommand
  implements TableauCommand<CreateConnectedAppCommandInput, CreateConnectedAppCommandOutput>
{
  method = 'POST';
  path = METHOD_PATH;

  constructor(
    public data: CreateConnectedAppCommandInput,
    public response?: CreateConnectedAppCommandOutput,
  ) {}
}

export interface DeleteConnectedAppCommandInput {
  clientId: string;
}

export class DeleteConnectedAppCommand implements TableauCommand<DeleteConnectedAppCommandInput> {
  method = 'DELETE';
  path: string;

  constructor(public data: DeleteConnectedAppCommandInput) {
    this.path = `${METHOD_PATH}/${this.data.clientId}`;
  }
}

export interface ListConnectedAppsCommandInput extends PathParameterInput {}

export interface ListConnectedAppsCommandOutput extends PaginatedOutput {
  connectedApplications: { connectedApplication?: ConnectedApp[] };
}

export class ListConnectedAppsCommand
  implements TableauCommand<undefined, ListConnectedAppsCommandOutput>
{
  method = 'GET';
  path = METHOD_PATH;
  response?: ListConnectedAppsCommandOutput;

  constructor(input?: ListConnectedAppsCommandInput) {
    const parameters = stringifyPathParameters(input);
    this.path = `${METHOD_PATH}${parameters}`;
  }
}

export interface CreateConnectedAppSecretCommandInput {
  clientId: string;
}

export interface CreateConnectedAppSecretCommandOutput {
  connectedApplicationSecret: {
    id: string;
    value: string;
    createdAt: string;
  };
}

export class CreateConnectedAppSecretCommand
  implements
    TableauCommand<CreateConnectedAppSecretCommandInput, CreateConnectedAppSecretCommandOutput>
{
  method = 'POST';
  path: string;

  constructor(
    public data: CreateConnectedAppSecretCommandInput,
    public response?: CreateConnectedAppSecretCommandOutput,
  ) {
    this.path = `${METHOD_PATH}/${this.data.clientId}/secrets`;
  }
}
