import React from 'react';
import { useConfig } from '@kikoda/generated-config-hooks';
import { CircularProgress } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { GetViewByPathCommand } from '~/common/tableau';
import { useGetTableauToken, useTableau } from '~/hooks/tableauProvider';
import colors from '~/sass/colors.scss';
import { CustomView, Tableau, Viz } from './tableau';
import { Toolbar } from './toolbar';
import type { WebConfig } from '~/web-config';

import './styles.scss';

const initialBackgroundColor = colors.rootBackground;
interface WorkbookProps {
  onWorkbookLoaded: (isWorkbookLoaded: boolean) => void;
  loadBackground: (backgroundColor: string) => void;
}

export default function WorkbookView(props: WorkbookProps) {
  const {
    additionalConfig: { tableauEndpoint },
  } = useConfig<WebConfig>();

  const { onWorkbookLoaded, loadBackground } = props;

  const { workbook, view } = useParams() as { workbook: string; view: string };
  const src = `${tableauEndpoint}/views/${workbook}/${view}`;

  const tableauClient = useTableau();
  const { token, isLoading } = useGetTableauToken(src);

  const [backgroundColor, setBackgroundColor] = React.useState(initialBackgroundColor);
  const container = React.useRef<HTMLDivElement>(null);
  const viz = React.useRef<Viz>(null);
  const [customViews, setCustomViews] = React.useState<CustomView[]>([]);
  const [activeCustomView, setActiveCustomView] = React.useState<CustomView>();

  const handleFirstInteractive = async () => {
    await reloadCustomViews();
    refreshActiveCustomView();
  };

  const reloadCustomViews = async () => {
    const views = await viz.current?.workbook?.getCustomViewsAsync();
    // Limit to only custom views based off of current workbook and view.
    const filtered = views?.filter(isBasedOffCurrentView);
    const sorted = filtered?.sort((a, b) => a.name.localeCompare(b.name));
    setCustomViews(sorted ?? []);
  };

  const refreshActiveCustomView = () => {
    const activeView = viz.current?.workbook?.activeCustomView;
    const view = isBasedOffCurrentView(activeView) ? activeView : undefined;
    setActiveCustomView(view);
  };

  // Determine if custom views is based off of current workbook and view.
  const isBasedOffCurrentView = (customView: CustomView | undefined) => {
    const { workbook: parsedWorkbook, view: parsedView } = parseCustomViewUrl(customView);
    return parsedWorkbook === workbook && parsedView === view;
  };

  // Parse custom view to determine workbook and view.
  const parseCustomViewUrl = (currentView: CustomView | undefined) => {
    const prefix = '/views/';
    const parsed = currentView?.url.split(prefix, 2).at(1)?.split('/', 2);
    return { workbook: parsed?.at(0), view: parsed?.at(1) };
  };

  const history = useHistory();

  // Listens when the user navigates away from the components and hides the comments component
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      console.log('User is leaving the page');
      onWorkbookLoaded(false);
    });

    return () => {
      unlisten();
    };
  });

  React.useEffect(() => {
    async function loadBackgroundColor() {
      // Wait for tableauClient to load.
      if (!tableauClient) return;

      // Wait for view to load.
      if (!view) return;

      // Load view background color.
      const response = await tableauClient.send(
        new GetViewByPathCommand({ workbookName: workbook, viewName: view }),
      );
      if (response.views.view?.at(0)) {
        localStorage.setItem('currentView', JSON.stringify(response.views.view?.at(0)));
        onWorkbookLoaded(true);
      }
      // Tags from initial view.
      const tags = response.views.view?.at(0)?.tags?.tag;
      const prefix = 'bg-';
      const backgroundColorTag = tags?.find((tag) =>
        tag.label.toLowerCase().startsWith(prefix),
      )?.label;
      const color = backgroundColorTag?.slice(prefix.length);
      setBackgroundColor(color ? `#${color}` : initialBackgroundColor);
      loadBackground(color ? `#${color}` : initialBackgroundColor);
    }

    loadBackgroundColor();
    return () => setBackgroundColor(initialBackgroundColor);
  }, [loadBackground, onWorkbookLoaded, setBackgroundColor, tableauClient, view, workbook]);

  if (isLoading) {
    return (
      <div id='workbook-view' className='loader'>
        <CircularProgress />
      </div>
    );
  }

  if (token === '-' || !token) {
    return (
      <div id='workbook-view' className='loader'>
        <span>Unable to load workbook. Tableau Authentication unavailable.</span>
      </div>
    );
  }

  return (
    <div id='workbook-view' style={{ backgroundColor }}>
      {/* Drawing outside the box */}
      <div className='outside' style={{ backgroundColor }} />
      <div ref={container} className='container' style={{ backgroundColor }}>
        <Toolbar
          container={container}
          viz={viz}
          customViews={customViews}
          activeCustomView={activeCustomView}
          reloadCustomViews={reloadCustomViews}
          refreshActiveCustomView={refreshActiveCustomView}
        />
        <div className='view-container'>
          <div className='view'>
            <Tableau
              ref={viz}
              src={src}
              token={token}
              hide-tabs
              toolbar='hidden'
              onFirstInteractive={handleFirstInteractive}
            />
          </div>
        </div>
      </div>
      <div className='outside-right' style={{ backgroundColor }} />
    </div>
  );
}
