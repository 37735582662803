import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

export const ConfirmDialog = ({
  isOpen,
  title,
  message,
  buttonText,
  onConfirm,
  onDeny,
}: {
  isOpen: boolean;
  title: string;
  message?: string;
  buttonText?: string;
  onConfirm: () => void;
  onDeny: () => void;
}) => {
  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message ?? `Are you sure?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDeny}>Cancel</Button>
          <Button onClick={onConfirm}>{buttonText ?? 'Confirm'}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
