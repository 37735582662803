import React, { useEffect, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import { toast } from 'react-toastify';
import { ApiClient, Comment, UserData } from '~/common';
import useApi from '~/hooks/apiProvider/useApi';
import { useTenant } from '~/hooks/tenantProvider';
import useLocalStorage from '~/hooks/useLocalStorage';
import { useUser } from '~/hooks/useUser';
import { ReactComponent as Arrow } from '~/images/arrow.svg';
import { ReactComponent as CommentIcon } from '~/images/comments-regular.svg';
import { ReactComponent as Diamond } from '~/images/diamond.svg';
import { ReactComponent as MenuToggle } from '~/images/menu-toggle.svg';
import { getCommentItems, addCommentItem, updateCommentItem } from './api';
import CommentItem from './comment';
import './styles.scss';

export default function Menu(): JSX.Element {
  const [collapsed, setCollapsed] = useLocalStorage('commentCollapsed', false);
  const [collapsedMenu, setCollapsedMenu] = useLocalStorage('collapsed', false);
  const [isLoading, setIsLoading] = useState(true);
  const [commentItems, setCommentItems] = useState([] as Comment[]);
  const [comment, setComment] = useState('');
  const [enableCommentButton, setButtonState] = React.useState(false);
  const [canCancel, showButton] = React.useState(false);
  const [selectedComment, setSelectedComment] = React.useState({} as Comment);

  const tenant = useTenant();
  const apiClient = useApi() as ApiClient;
  const { user } = useUser();
  const [currentView] = useLocalStorage('currentView', '');

  useEffect(() => {
    const loadCommentItems = async () => {
      if (!apiClient) return;
      const view = JSON.parse(JSON.stringify(currentView));
      const { data = [], error } = await getCommentItems(apiClient, view.id);
      if (error) toast(error, { toastId: 'api-error' });

      if (data?.length > 0) {
        setCommentItems(data);
        setCollapsed(false);
      } else {
        setCollapsed(true);
      }
      setIsLoading(false);
    };
    loadCommentItems();
    // Remove dependencies due to endless created by the setCollapsed.The lint rule was disabled for this line so the solutin build. DO NOT ENABLE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient]);

  const handleToggleCollapsed = () => {
    setCollapsed((e) => !e);
    if (collapsedMenu === false) {
      setCollapsedMenu(true);
    }
  };

  const saveComment = async () => {
    const view = JSON.parse(JSON.stringify(currentView));

    if (selectedComment?.id) {
      await updateComment(view.id);
    } else {
      await addComment(view.id);
    }
  };

  const getComments = async () => {
    if (!apiClient) return;
    const view = JSON.parse(JSON.stringify(currentView));
    const { data = [], error } = await getCommentItems(apiClient, view.id);
    if (error) toast(error, { toastId: 'api-error' });

    if (data) {
      setCommentItems(data);
    }
    setIsLoading(false);
  };

  const updateComment = async (viewId: string) => {
    const editedComment: Comment = {
      datePosted: selectedComment?.datePosted,
      isEdited: true,
      tenandId: selectedComment.tenandId,
      text: comment,
      userId: selectedComment.userId,
      commentUser: selectedComment.commentUser,
      viewId: selectedComment.viewId,
      id: selectedComment.id,
      likes: selectedComment.likes,
    };
    editedComment.text = comment;
    await updateCommentItem(apiClient, editedComment);
    displayCancelButton(false);
    setSelectedComment({} as Comment);
    setButtonState(false);

    // Refresh comments
    await getComments();
  };

  const addComment = async (viewId: string) => {
    const newComment: Comment = {
      tenandId: tenant.id ?? '',
      viewId: viewId,
      text: comment,
      datePosted: new Date(),
      isEdited: false,
      likes: [],
    };
    const { data } = await addCommentItem(apiClient, newComment);
    const commentUser: UserData = {
      id: user?.attributes?.sub ?? '',
      name: `${user?.attributes?.given_name} ${user?.attributes?.family_name}`,
    };
    newComment.id = data;
    newComment.commentUser = commentUser;
    commentItems.push(newComment as Comment);
    setComment('');
    setButtonState(false);
  };

  const getSelectedComment = (_selectedComment: any): any => {
    if (
      _selectedComment.user &&
      user?.attributes?.sub &&
      _selectedComment.user.id === user?.attributes?.sub
    ) {
      setSelectedComment(_selectedComment);
      setComment(_selectedComment.text);
      setButtonState(true);
      showButton(true);
    } else {
      setComment('');
    }
  };

  const displayCancelButton = (value: boolean) => {
    showButton(value);
    setComment('');
  };

  const removeDeletedComment = (deletedCommentId?: string) => {
    const deletedComment = commentItems.find((x) => x.id === deletedCommentId);

    if (!deletedComment) {
      return;
    }

    const deletedCommentIndex = commentItems.indexOf(deletedComment);

    if (deletedCommentIndex === -1) {
      return;
    }

    const data = commentItems.filter((comment) => comment.id !== deletedCommentId);
    setCommentItems(data);
  };

  return (
    <nav id='comment-navigation' className={`theme-dark ${collapsed ? 'collapsed' : ''}`}>
      <div className='header'>
        <div className='logo-container'>
          <CommentIcon width={20} />
          {!collapsed && <span className='title'>Comments</span>}
        </div>
      </div>

      {collapsed ? (
        <button className='expand-button' onClick={handleToggleCollapsed}>
          <Arrow className='arrow z-10' />
          <Diamond className='toggle color-dark brightness-[.80]' />
        </button>
      ) : (
        <button className='collapse-button' onClick={handleToggleCollapsed}>
          <Arrow className='arrow z-10' />
          <MenuToggle className='toggle color-dark brightness-[.80]' />
        </button>
      )}
      <section className='body'>
        {!collapsed && (
          <ul>
            {isLoading
              ? Array.from({ length: 6 })?.map((_, index) => (
                  <Skeleton key={index} variant='rounded' width='100%' height={45} />
                ))
              : commentItems.map((item, idx) => (
                  <CommentItem
                    datePosted={new Date(item.datePosted)}
                    isEdited={item.isEdited}
                    tenandId={item.tenandId}
                    text={item.text}
                    user={item.commentUser}
                    viewId={item.viewId}
                    id={item.id}
                    likes={item.likes}
                    sendData={getSelectedComment}
                    commentDeleted={removeDeletedComment}
                  />
                ))}
          </ul>
        )}
      </section>
      <footer className='footer'>
        {!collapsed && (
          <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap={4}>
            <Box gridColumn='span 12'>
              <span className='text'>Add a comment</span>
            </Box>
          </Box>
        )}

        {!collapsed && (
          <input
            placeholder='Say something'
            className='input'
            value={comment}
            onChange={(event) => {
              setButtonState(event.target.value ? true : false);
              setComment(event.target.value);
            }}></input>
        )}

        {!collapsed && (
          <div className='actions'>
            <div className='attachment-container'></div>
            <input
              className={enableCommentButton ? 'theme-main' : 'theme-inverse outlined disabled'}
              type='button'
              value={`${canCancel === true ? 'Save Changes' : 'Post'}`}
              disabled={!enableCommentButton}
              onClick={() => saveComment()}
            />
            <div></div>
            {canCancel === true && (
              <input
                className={'error outlined disabled'}
                type='button'
                value='Cancel'
                disabled={!enableCommentButton}
                onClick={() => displayCancelButton(false)}
              />
            )}
          </div>
        )}
      </footer>
    </nav>
  );
}
