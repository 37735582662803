import React from 'react';
import { loadConfig } from '@kikoda/generated-config-hooks';
import { Provider as ConfigProvider } from '@kikoda/generated-config-hooks/lib/hooks/context';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import App from '~/App';
import { Splash } from '~/components/Elements/splash';
import reportWebVitals from '~/reportWebVitals';
import type { WebConfig } from '~/web-config';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<Splash />);

async function render(host: string = '') {
  // Load config.
  const config = await loadConfig<WebConfig>(host);

  axios.defaults.baseURL = config.additionalConfig.platform.backend.endpoint;

  root.render(
    <React.StrictMode>
      <ConfigProvider value={{ config }}>
        <App />
      </ConfigProvider>
    </React.StrictMode>,
  );
}

// Async blocking render.
render().catch((error) => {
  console.error(error);
  root.render(<h1>Error</h1>);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
