import React from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as FullScreen } from '~/images/full-screen.svg';
import { ReactComponent as Photo } from '~/images/photo.svg';
import { ReactComponent as Refresh } from '~/images/refresh.svg';
import { ReactComponent as RotateLeftClock } from '~/images/rotate-left-clock.svg';
import { ReactComponent as RotateLeft } from '~/images/rotate-left.svg';
import { ReactComponent as RotateRight } from '~/images/rotate-right.svg';
import { ReactComponent as TableColumns } from '~/images/table-columns.svg';
import { useOpen } from '~/utils/useOpen';
import { CustomViewsDialog } from './CustomViewsDialog';
import type { CustomView, Viz } from './tableau';

export type CustomViewsActions = {
  add: (viewName: string) => Promise<void>;
  delete: (view: CustomView) => Promise<void>;
  save: (view: CustomView) => Promise<void>;
  show: (view: CustomView) => Promise<void>;
  showOriginal: () => Promise<void>;
  update: (view: CustomView, update: Partial<CustomView>) => Promise<void>;
};

export interface ToolbarProps {
  container: React.RefObject<HTMLDivElement>;
  viz: React.RefObject<Viz>;
  customViews: Array<CustomView>;
  activeCustomView: CustomView | undefined;
  reloadCustomViews: () => Promise<void>;
  refreshActiveCustomView: () => void;
}

export function Toolbar({
  container,
  viz,
  customViews,
  activeCustomView,
  reloadCustomViews,
  refreshActiveCustomView,
}: ToolbarProps) {
  const customViewsDialog = useOpen(false);

  const customViewsActions: CustomViewsActions = {
    /** Add custom view. */
    add: async (viewName: string) => {
      await viz.current?.workbook?.saveCustomViewAsync(viewName);
      await reloadCustomViews();
      refreshActiveCustomView();
    },

    /** Delete custom view. */
    delete: async (view: CustomView) => {
      await viz.current?.workbook?.removeCustomViewAsync(view.name);
      await reloadCustomViews();
      refreshActiveCustomView();
    },

    /** Save current view state. */
    save: async (view: CustomView) => {
      await viz.current?.workbook?.saveCustomViewAsync(view.name);
    },

    /** Show custom view. */
    show: async (view: CustomView) => {
      customViewsDialog.close();
      await viz.current?.workbook?.showCustomViewAsync(view.name);
      refreshActiveCustomView();
    },

    /** Show original view. */
    showOriginal: async () => {
      customViewsDialog.close();
      try {
        // Causes a temporary Tableau error dialog to be displayed.
        await viz.current?.workbook?.showCustomViewAsync(undefined);
      } catch (error) {} // Ignore error.
      refreshActiveCustomView();
    },

    /** Update custom view properties. */
    update: async (view: CustomView, update: Partial<CustomView>) => {
      if (update.name !== undefined) {
        view.name = update.name;
      }
      await view.saveAsync();

      await reloadCustomViews();
      refreshActiveCustomView();
    },
  };

  const exportImage = async () => {
    await viz.current?.exportImageAsync();
  };

  const fullScreen = () => {
    if (!document.fullscreenElement) {
      container.current?.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const openCustomViewsDialog = async () => {
    customViewsDialog.open();
  };

  const redo = async () => {
    await viz.current?.redoAsync();
  };

  const refresh = async () => {
    await viz.current?.refreshDataAsync();
    toast('View refreshed with the latest data.');
  };

  const reset = async () => {
    await viz.current?.revertAllAsync();
  };

  const undo = async () => {
    await viz.current?.undoAsync();
  };

  return (
    <div className='flex'>
      <div className='flex flex-wrap justify-center gap-y-2 theme-dark-inverse text-xs mx-auto rounded-b-2xl divide-x-2 divide-y-0 divide-slate-200 divide-solid'>
        <button className='p-4 rounded-bl-2xl' onClick={undo}>
          <RotateLeft className='shrink-0' width={14} />
          Undo
        </button>
        <button className='p-4' onClick={redo}>
          <RotateRight className='shrink-0' width={14} />
          Redo
        </button>
        <button className='p-4' onClick={reset}>
          <RotateLeftClock className='shrink-0' width={14} />
          Reset
        </button>
        <button className='p-4' onClick={refresh}>
          <Refresh className='shrink-0' width={14} />
          Refresh
        </button>
        <button className='p-4' onClick={exportImage}>
          <Photo className='shrink-0' width={14} />
          Export Image
        </button>
        <button className='p-4' onClick={openCustomViewsDialog}>
          <TableColumns className='shrink-0' width={14} />
          Custom Views
        </button>
        <button className='p-4 rounded-br-2xl' onClick={fullScreen}>
          <FullScreen className='shrink-0' width={14} />
          Full Screen
        </button>
      </div>
      <CustomViewsDialog
        customViews={customViews}
        activeCustomView={activeCustomView}
        actions={customViewsActions}
        open={customViewsDialog.isOpen}
        onClose={customViewsDialog.close}
      />
    </div>
  );
}
