import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link, useRouteMatch } from 'react-router-dom';
import { ReactComponent as Arrow } from '~/images/arrow.svg';
import { MenuItemData } from './api';

import './menuItem.scss';

export interface MenuItemProps {
  text: string;
  path?: string;
  subMenuItems: MenuItemData[];
}

export default function MenuItem(props: MenuItemProps): JSX.Element {
  const subitem = props.subMenuItems[0]; // this is the first View in the Workbook
  const CUrlWorkbook = subitem.path?.split('/')[0] ?? props.text.replaceAll(' ', '');
  const CUrlView = subitem.path?.split('/')[1];

  // Get the active workbook and view from the URL
  const match = useRouteMatch('/workbook/:workbook/view/:view') as {
    params: { workbook: string; view: string };
  };
  const activeItem = `${match?.params?.workbook}/${match?.params?.view}`;

  const expandedItem = activeItem.startsWith(CUrlWorkbook) ? CUrlWorkbook : '';
  const [isExpanded, setIsExpanded] = useState<string>();

  // Initialize the expanded state
  useEffect(() => {
    setIsExpanded(expandedItem);
  }, [expandedItem]);

  const toggleExpanded = (item: string) => {
    setIsExpanded(isExpanded === item ? '' : item);
  };

  if (props.subMenuItems.length <= 1) {
    return (
      <li
        id='menu-item'
        className={classNames('single', { active: activeItem === `${CUrlWorkbook}/${CUrlView}` })}>
        <Link type='button' to={`/workbook/${CUrlWorkbook}/view/${CUrlView}`}>
          {props.text}
          <Arrow className='arrow' />
        </Link>
      </li>
    );
  }

  return (
    <li
      id='menu-item'
      className={classNames('multi', { 'is-expanded': isExpanded === CUrlWorkbook })}>
      <button type='button' onClick={() => toggleExpanded(CUrlWorkbook)}>
        {props.text}
        <Arrow className='arrow' />
      </button>
      {isExpanded && (
        <ul>
          {props.subMenuItems.map((subitem, idx) => {
            const CUrlWorkbook = subitem.path?.split('/')[0];
            const CUrlView = subitem.path?.split('/')[1];
            return (
              <li
                key={idx}
                className={classNames({
                  'active backdrop-brightness-90': activeItem === `${CUrlWorkbook}/${CUrlView}`,
                })}>
                <Link to={`/workbook/${CUrlWorkbook}/view/${CUrlView}`} title={subitem.text}>
                  {subitem.text}
                  <Arrow className='arrow' />
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
}
