import React from 'react';
import { useExternalScript } from '~/hooks/useExternalScript';
import { ValueOf } from '~/utils/utilityTypes';

/// Incomplete collection of Tableau types.

export type TableauDialogType = ValueOf<typeof TableauDialogType>;
export const TableauDialogType = {
  ExportCrossTab: 'export-cross-tab',
  ExportData: 'export-data',
  ExportPDF: 'export-pdf',
  ExportPowerPoint: 'export-power-point',
  ExportWorkbook: 'export-workbook',
  Share: 'share',
} as const;

export type TableauEventType = ValueOf<typeof TableauEventType>;
export const TableauEventType = {
  // Authoring Enumeration members
  WorkbookPublished: 'workbookpublished',
  WorkbookPublishedAs: 'workbookpublishedas',
  WorkbookReadyToClose: 'workbookreadytoclose',
  // Authoring and Viewing Enumeration members
  EditInDesktopButtonClicked: 'editindesktopbuttonclicked',
  FirstInteractive: 'firstinteractive',
  FirstVizSizeKnown: 'firstvizsizeknown',
  // Viewing Enumeration members
  CustomMarkContextMenuEvent: 'custommarkcontextmenuevent',
  CustomViewLoaded: 'customviewloaded',
  CustomViewRemoved: 'customviewremoved',
  CustomViewSaved: 'customviewsaved',
  CustomViewSetDefault: 'customviewsetdefault',
  EditButtonClicked: 'editbuttonclicked',
  FilterChanged: 'filterchanged',
  MarkSelectionChanged: 'markselectionchanged',
  ParameterChanged: 'parameterchanged',
  StoryPointSwitched: 'storypointswitched',
  TabSwitched: 'tabswitched',
  ToolbarStateChanged: 'toolbarstatechanged',
  UrlAction: 'urlaction',
};

export interface CustomView {
  /** Properties */
  default: boolean;
  name: string;
  ownerName: string;
  shared: boolean;
  url: string;
  workbook: Workbook;

  /** Methods */
  saveAsync: () => Promise<CustomView>;
}

export interface Workbook {
  /** Properties */
  activeCustomView: CustomView | undefined;
  // activeSheet;
  name: string;
  // publishedSheetsInfo;
  /** Methods */
  // activateSheetAsync;
  // changeParameterValueAsync;
  getCustomViewsAsync: () => Promise<Array<CustomView>>;
  // getParametersAsync;
  removeCustomViewAsync: (customViewName: string) => Promise<CustomView>;
  revertAllAsync: () => Promise<void>;
  saveCustomViewAsync: (customViewName: string) => Promise<CustomView>;
  setActiveCustomViewAsDefaultAsync: () => Promise<void>;
  showCustomViewAsync: (customViewName: string | undefined) => Promise<CustomView>;
}

export interface Viz {
  /** Properties */
  /// Additional properties not specified...
  workbook: Workbook | undefined;

  /** Methods */
  addEventListener: (
    type: TableauEventType,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ) => void;
  addFilter: (fieldName: string, value: string) => void;
  displayDialogAsync: (type: TableauDialogType) => Promise<void>;
  exportImageAsync: () => Promise<void>;
  getCurrentSrcAsync: () => Promise<string>;
  pauseAutomaticUpdatesAsync: () => Promise<void>;
  redoAsync: () => Promise<void>;
  refreshDataAsync: () => Promise<void>;
  removeEventListener: (
    type: TableauEventType,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions,
  ) => void;
  resumeAutomaticUpdatesAsync: () => Promise<void>;
  revertAllAsync: () => Promise<void>;
  toggleAutomaticUpdatesAsync: () => Promise<void>;
  undoAsync: () => Promise<void>;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'tableau-viz': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
      > & {
        src?: string;
        token?: string | null;
        'hide-tabs'?: boolean;
        toolbar?: 'bottom' | 'top' | 'left' | 'right' | 'hidden';
        height?: string;
        width?: string;
      };
    }
  }
}

export interface TableauProps {
  src: string;
  id?: string;
  'hide-tabs'?: boolean;
  height?: string;
  token?: string | null;
  toolbar?: 'bottom' | 'top' | 'left' | 'right' | 'hidden';
  width?: string;
  onCustomViewLoaded?: () => void;
  onCustomViewRemoved?: () => void;
  onCustomViewSaved?: () => void;
  onCustomViewSetDefault?: () => void;
  onFirstInteractive?: () => void;
  onFirstVizSizeKnown?: () => void;
}

export const Tableau = React.forwardRef<Viz, TableauProps>((props, ref) => {
  const innerRef = React.useRef<HTMLDivElement>(null);
  React.useImperativeHandle(ref, () => innerRef.current as unknown as Viz);

  /* Load Tableau script. */
  useExternalScript('https://online.tableau.com/javascripts/api/tableau.embedding.3.5.0.min.js', {
    type: 'module',
  });

  /* Add events. */
  const addEvent = React.useCallback(
    (type: string, listener: EventListenerOrEventListenerObject | undefined) => {
      if (listener) {
        innerRef.current?.addEventListener(type, listener);
      }
      return () => {
        if (listener) {
          innerRef.current?.removeEventListener(type, listener);
        }
      };
    },
    [],
  );

  React.useEffect(() => {
    return addEvent(TableauEventType.CustomViewLoaded, props.onCustomViewLoaded);
  }, [addEvent, props.onCustomViewLoaded]);

  React.useEffect(() => {
    return addEvent(TableauEventType.CustomViewRemoved, props.onCustomViewRemoved);
  }, [addEvent, props.onCustomViewRemoved]);

  React.useEffect(() => {
    return addEvent(TableauEventType.CustomViewSaved, props.onCustomViewSaved);
  }, [addEvent, props.onCustomViewSaved]);

  React.useEffect(() => {
    return addEvent(TableauEventType.CustomViewSetDefault, props.onCustomViewSetDefault);
  }, [addEvent, props.onCustomViewSetDefault]);

  React.useEffect(() => {
    return addEvent(TableauEventType.FirstInteractive, props.onFirstInteractive);
  }, [addEvent, props.onFirstInteractive]);

  React.useEffect(() => {
    return addEvent(TableauEventType.FirstVizSizeKnown, props.onFirstVizSizeKnown);
  }, [addEvent, props.onFirstVizSizeKnown]);

  return (
    <tableau-viz
      id={props.id ?? 'tableau-viz'}
      src={props.src}
      hide-tabs={props['hide-tabs']}
      height={props.height}
      ref={innerRef}
      token={props.token}
      toolbar={props.toolbar}
      width={props.width}
    />
  );
});
