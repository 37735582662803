import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import CircularProgress from '~/components/Elements/CirculatorProgress';
import { useUser } from '~/hooks/useUser';

const ProtectedRoute = ({ children, path }: { children: JSX.Element; path: string }) => {
  const { user, isAdminUser } = useUser();

  if (!user) {
    return <CircularProgress message='Checking User Authorization' />;
  }
  if (!isAdminUser) {
    toast.error('You are not authorized to view this page', { toastId: 'ProtectedRoute' });
  }
  return <Route path={path} render={() => (isAdminUser ? children : <Redirect to='/home' />)} />;
};

export default ProtectedRoute;
