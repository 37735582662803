import { Amplify } from 'aws-amplify';

export interface AmplifyConfig {
  readonly region: string;
  readonly userPoolId?: string;
  readonly userPoolWebClientId?: string;
}

export const configureAmplify = (config: AmplifyConfig): void => {
  const { region, userPoolId, userPoolWebClientId } = config;

  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId,

      // @todo - Add oauth support
      // oauth: {
      //   domain: `${authDomain}.auth.${authRegion}.amazoncognito.com`,
      //   scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      //   redirectSignIn: loginURI,
      //   redirectSignOut: logoutURI,
      //   responseType: 'code',
      // },
    },
  });
};
