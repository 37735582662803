import { CognitoUser } from '@aws-amplify/auth';
import axios from 'axios';
import { LochUser } from '~/hooks/useUser';

export class Api {
  public static clearAuthorizationHeader() {
    delete axios.defaults.headers.common.Authorization;
  }

  public static setAuthHeaders(user?: CognitoUser) {
    if (user) {
      const session = user.getSignInUserSession();

      if (session) {
        const token = session.getIdToken().getJwtToken();

        axios.defaults.headers.common.Authorization = token;
      }
    }
  }

  public static getToken(user?: LochUser | null) {
    if (user) {
      const session = user.getSignInUserSession();

      if (session) {
        const token = user.getSignInUserSession()?.getIdToken().getJwtToken();

        return token;
      }
    }
  }
}
