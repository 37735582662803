import { ReactComponent as CheckOutline } from '~/images/check-outline.svg';
import { ReactComponent as CheckSolid } from '~/images/check-solid.svg';
import type { CustomView } from '../tableau';
import type { CustomViewsActions } from '../toolbar';

export interface OriginalViewProps {
  activeCustomView: CustomView | undefined;
  actions: CustomViewsActions;
}

export function OriginalView({ activeCustomView, actions }: OriginalViewProps) {
  const handleResetView = async () => {
    await actions.showOriginal();
  };

  const isActiveCustomView = !activeCustomView;

  return (
    <li className='flex'>
      <button onClick={handleResetView} title='Switch to this view'>
        {isActiveCustomView ? <CheckSolid width={16} /> : <CheckOutline width={16} />}
      </button>

      <div className='text-black font-semibold grow'>
        <button className='w-full' onClick={handleResetView}>
          Original
        </button>
      </div>
    </li>
  );
}
