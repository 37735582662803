import { useState, useEffect } from 'react';
import { useConfig } from '@kikoda/generated-config-hooks';
import axios from 'axios';
import { Api } from '~/common/utils/api';
import { WebConfig } from '~/web-config';
import { useUser } from '../useUser';

export interface TenantProfile {
  id?: string;
  name?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactPhone?: string;
}

async function loadGetTenantProfile(token: any, apiEndpoint: string): Promise<TenantProfile> {
  let profile: TenantProfile = {};
  if (!token) return profile;

  try {
    const api = axios.create();

    const response = await api.get<TenantProfile>(`${apiEndpoint}/portal/profile`, {
      headers: {
        Authorization: token,
      },
    });
    profile = response.data;
  } catch (error) {
    console.debug('Error getting tenant profile', error);
  } finally {
    return profile;
  }
}

export const useGetTenantProfile = () => {
  const config = useConfig<WebConfig>();
  const [profile, setProfile] = useState<TenantProfile>({});
  const { user } = useUser();
  const { tenantServiceEndpoint } = config?.additionalConfig?.platform?.backend;
  useEffect(() => {
    const getTenantUsers = async () => {
      const profile = await loadGetTenantProfile(Api.getToken(user), tenantServiceEndpoint);
      setProfile(profile);
    };
    getTenantUsers();
  }, [tenantServiceEndpoint, user]);
  return { profile };
};
