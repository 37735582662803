import { useState, useEffect } from 'react';
import axios from 'axios';
import { WebConfig } from '~/web-config';
import type { Tenant, TenantStyle } from './context';

const storageKey = (subDomain: string) => `tenant.appConfig.v2.${subDomain}`;
const isReady = (status?: string) =>
  (status?.toLowerCase() !== 'provisioning' &&
    status?.toLowerCase() !== 'new' &&
    status?.toLowerCase() !== 'failed') as boolean;

export interface GetTenantConfigResponse {
  id: string;
  name: string;
  status: string;
  authType?: string;
  userPoolId?: string;
  appClientId?: string;
  industryId?: string;
  industryName?: string;
  stage: string;
  allowSelfRegistration: boolean;
  style?: TenantStyle;
}

async function loadTenant(apiEndpoint: string, tenantSubDomain: string): Promise<Tenant> {
  let found;
  let ready = true;
  let config: GetTenantConfigResponse = {
    id: '',
    name: '',
    status: 'NEW',
    stage: '',
    allowSelfRegistration: false,
  };

  try {
    const response = await axios.get<GetTenantConfigResponse>(
      `${apiEndpoint}/tenant-configs/${tenantSubDomain}`,
    );

    config = response.data;
    found = true;
    ready = config.status ? isReady(config.status) : true;
  } catch (error) {
    found = false;
  }

  const tenant = {
    found,
    ready,
    subDomain: tenantSubDomain,
    isLoading: false,
    ...config,
  };

  return tenant;
}

export const useLoadTenant = (config: WebConfig): { tenant: Tenant } => {
  const [tenant, setTenant] = useState<Tenant>();

  const splitDomain = window.location.hostname.split('.');
  const subDomain = splitDomain[0];
  const cached = JSON.parse(
    localStorage.getItem(storageKey(subDomain)) ??
      JSON.stringify({ found: false, isLoading: true }),
  ) as Tenant;

  const { tenantServiceEndpoint } = config.additionalConfig.platform.backend;

  useEffect(() => {
    const getTenant = async () => {
      const t = await loadTenant(tenantServiceEndpoint, subDomain);

      // only save if the tenant is done provisioning
      if (t.status?.toLowerCase() === 'active') {
        localStorage.setItem(storageKey(subDomain), JSON.stringify(t));
      }

      setTenant(t);
    };
    getTenant();
  }, [subDomain, tenantServiceEndpoint]);

  return { tenant: tenant ?? cached };
};
