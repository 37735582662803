import React, { useEffect } from 'react';
import { useConfig } from '@kikoda/generated-config-hooks';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AuthenticatedWrapper from '~/components/AuthenticatedWrapper';
import { Authenticator } from '~/components/Authenticator';
import Message from '~/components/Elements/message';
import { Splash } from '~/components/Elements/splash';
import { useLoadTenant } from '~/hooks/tenantProvider';
import { Provider as TenantProvider } from '~/hooks/tenantProvider';
import { useSetTheme } from '~/hooks/useSetTheme';
import NotFound from '~/pages/NotFound';
import colors from '~/sass/colors.scss';
import { configureAmplify } from '~/utils/configureAmplify';
import type { WebConfig } from '~/web-config';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-toastify/dist/ReactToastify.css';
import '~/sass/default.scss';
import '~/sass/layout.scss';

export default function App(): JSX.Element {
  const config = useConfig<WebConfig>();
  const { tenant } = useLoadTenant(config);
  useSetTheme(tenant.style);

  const theme = createTheme({
    palette: {
      primary: {
        main: tenant.style?.colorMain || colors.colorMain,
      },
      secondary: {
        main: tenant.style?.colorLight || colors.colorLight,
      },
      background: {
        default: colors.rootBackground,
      },
    },
  });

  useEffect(() => {
    if (tenant.userPoolId) {
      configureAmplify({
        region: config.additionalConfig.platform.auth.authRegion,
        userPoolId: tenant.userPoolId,
        userPoolWebClientId: tenant.appClientId,
      });
    }
  }, [config.additionalConfig.platform.auth.authRegion, tenant.appClientId, tenant.userPoolId]);

  return (
    <TenantProvider value={tenant}>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position='bottom-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
        <BrowserRouter>
          {!tenant.found && tenant.isLoading && <Splash />}
          {!tenant.found && !tenant.isLoading && <NotFound />}
          {tenant.found && !tenant.ready && !tenant.isLoading && (
            <Message>Provisioning... please wait</Message>
          )}
          {tenant.found && tenant.ready && (
            <Authenticator>
              <AuthenticatedWrapper />
            </Authenticator>
          )}
        </BrowserRouter>
      </ThemeProvider>
    </TenantProvider>
  );
}
