import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { useTableau } from '~/hooks/tableauProvider';
import { useTenant } from '~/hooks/tenantProvider';
import { getMenuItems, MenuItemData } from './api';
import MenuItem from './menuItem';

interface MainMenuProps {
  collapsed: boolean;
}

export default function MainMenu(props: MainMenuProps): JSX.Element {
  const tenant = useTenant();
  const tableauClient = useTableau();
  const [menuItems, setMenuItems] = useState([] as MenuItemData[]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadMenuItems = async () => {
      // Wait for tableauClient to load.
      if (!tableauClient) return;

      const { data = [] } = await getMenuItems(tableauClient, tenant);
      // if (error) toast(error, { toastId: 'tableau-error' });

      setMenuItems(data);
      setIsLoading(false);
    };

    loadMenuItems();
  }, [tableauClient, tenant]);

  return (
    <section className='body'>
      {!props.collapsed && (
        <ul>
          {isLoading
            ? Array.from({ length: 6 }).map((_, index) => (
                <Skeleton key={index} variant='rounded' width='100%' height={45} />
              ))
            : menuItems.map((item, idx) => (
                <MenuItem
                  key={idx.toString()}
                  text={item.text}
                  subMenuItems={item.subMenuItems}
                  path={item.path}
                />
              ))}
        </ul>
      )}
    </section>
  );
}
