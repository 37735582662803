import { useState, useCallback } from 'react';

export function useOpen(initial: boolean): {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: (status: unknown) => void;
} {
  const [isOpen, setIsOpen] = useState(initial);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const toggle = useCallback(
    (status: unknown) => setIsOpen(typeof status === 'boolean' ? status : (toggle) => !toggle),
    [],
  );

  return { isOpen, open, close, toggle };
}
