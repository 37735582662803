import { TableauCommand } from './command';

export enum CapabilityMode {
  Allow = 'Allow',
  Deny = 'Deny',
}

export enum CapabilityName {
  AddComment = 'AddComment',
  ChangeHierarchy = 'ChangeHierarchy',
  ChangePermissions = 'ChangePermissions',
  Connect = 'Connect',
  Delete = 'Delete',
  Execute = 'Execute',
  ExportData = 'ExportData',
  ExportImage = 'ExportImage',
  ExportXml = 'ExportXml',
  Filter = 'Filter',
  InheritedProjectLeader = 'InheritedProjectLeader',
  ProjectLeader = 'ProjectLeader',
  Read = 'Read',
  ShareView = 'ShareView',
  ViewComments = 'ViewComments',
  ViewUnderlyingData = 'ViewUnderlyingData',
  WebAuthoring = 'WebAuthoring',
  Write = 'Write',
}

export interface Capability {
  name: CapabilityName;
  mode: CapabilityMode;
}

export interface GranteeCapabilities {
  capabilities: { capability: Capability[] };
  user?: { id: string };
  group?: { id: string };
}

export enum PermissionResource {
  Workbooks = 'workbooks',
  DataSources = 'datasources',
  DataRoles = 'dataroles',
  Lenses = 'lenses',
  Flows = 'flows',
  Metrics = 'metrics',
}

export enum ContentPermission {
  LockedToProject = 'LockedToProject',
  ManagedByOwner = 'ManagedByOwner',
  LockedToProjectWithoutNested = 'LockedToProjectWithoutNested',
}

export interface AddDefaultPermissionCommandInput {
  projectId: string;
  resource: PermissionResource;
  capabilities: GranteeCapabilities[];
}

export interface AddDefaultPermissionCommandOutput {
  permissions: {
    project: {
      id: string;
      name: string;
      owner?: { id: string };
    };
    granteeCapabilities?: GranteeCapabilities[];
  };
}

interface AddDefaultPermissionCommandRequest {
  permissions: { granteeCapabilities: GranteeCapabilities[] };
}

export class AddDefaultPermissionCommand
  implements TableauCommand<AddDefaultPermissionCommandRequest, AddDefaultPermissionCommandOutput>
{
  method = 'PUT';
  path: string;
  data: AddDefaultPermissionCommandRequest;
  response?: AddDefaultPermissionCommandOutput;

  constructor(input: AddDefaultPermissionCommandInput) {
    this.path = `projects/${input.projectId}/default-permissions/${input.resource}`;
    this.data = { permissions: { granteeCapabilities: input.capabilities } };
  }
}

export interface AddProjectPermissionCommandInput {
  projectId: string;
  capabilities: GranteeCapabilities[];
}

export interface AddProjectPermissionCommandOutput {
  permissions: {
    project: {
      id: string;
      name: string;
      owner?: { id: string };
    };
    granteeCapabilities?: GranteeCapabilities[];
  };
}

interface AddProjectPermissionCommandRequest {
  permissions: { granteeCapabilities: GranteeCapabilities[] };
}

export class AddProjectPermissionCommand
  implements TableauCommand<AddProjectPermissionCommandRequest, AddProjectPermissionCommandOutput>
{
  method = 'PUT';
  path: string;
  data: AddProjectPermissionCommandRequest;
  response?: AddProjectPermissionCommandOutput;

  constructor(input: AddProjectPermissionCommandInput) {
    this.path = `projects/${input.projectId}/permissions`;
    this.data = { permissions: { granteeCapabilities: input.capabilities } };
  }
}

export type DeleteProjectPermissionCommandInput = {
  projectId: string;
  groupId?: string;
  userId?: string;
  capabilityName: CapabilityName;
  capabilityMode: CapabilityMode;
};

export class DeleteProjectPermissionCommand implements TableauCommand<undefined, undefined> {
  method = 'DELETE';
  path: string;
  response = undefined;

  constructor(input: DeleteProjectPermissionCommandInput) {
    if ((!input.groupId && !input.userId) || (input.groupId && input.userId)) {
      throw new Error(
        'Invalid parameters: Either `groupId` or `userId` must be set, but not both.',
      );
    }

    const target = input.groupId ? `groups/${input.groupId}` : `users/${input.userId}`;
    this.path = `projects/${input.projectId}/permissions/${target}/${input.capabilityName}/${input.capabilityMode}`;
  }
}

export type DeleteDefaultPermissionCommandInput = {
  projectId: string;
  resource: PermissionResource;
  groupId?: string;
  userId?: string;
  capabilityName: CapabilityName;
  capabilityMode: CapabilityMode;
};

export class DeleteDefaultPermissionCommand implements TableauCommand<undefined, undefined> {
  method = 'DELETE';
  path: string;
  response = undefined;

  constructor(input: DeleteDefaultPermissionCommandInput) {
    if ((!input.groupId && !input.userId) || (input.groupId && input.userId)) {
      throw new Error(
        'Invalid parameters: Either `groupId` or `userId` must be set, but not both.',
      );
    }

    const target = input.groupId ? `groups/${input.groupId}` : `users/${input.userId}`;
    this.path = `projects/${input.projectId}/default-permissions/${input.resource}/${target}/${input.capabilityName}/${input.capabilityMode}`;
  }
}

export interface ListDefaultPermissionsCommandInput {
  projectId: string;
  resource: PermissionResource;
}

export interface ListDefaultPermissionsCommandOutput {
  permissions: {
    project: {
      id: string;
      name: string;
      owner?: { id: string };
    };
    granteeCapabilities?: GranteeCapabilities[];
  };
}

export class ListDefaultPermissionsCommand
  implements TableauCommand<undefined, ListDefaultPermissionsCommandOutput>
{
  method = 'GET';
  path: string;
  response?: ListDefaultPermissionsCommandOutput;

  constructor(input: ListDefaultPermissionsCommandInput) {
    this.path = `projects/${input.projectId}/default-permissions/${input.resource}`;
  }
}

export interface ListProjectPermissionsCommandInput {
  projectId: string;
}

export interface ListProjectPermissionsCommandOutput {
  permissions: {
    project: {
      id: string;
      name: string;
      owner?: { id: string };
    };
    granteeCapabilities?: GranteeCapabilities[];
  };
}

export class ListProjectPermissionsCommand
  implements TableauCommand<undefined, ListProjectPermissionsCommandOutput>
{
  method = 'GET';
  path: string;
  response?: ListProjectPermissionsCommandOutput;

  constructor(input: ListProjectPermissionsCommandInput) {
    this.path = `projects/${input.projectId}/permissions`;
  }
}

export function extractCapabilities(permissions: { granteeCapabilities?: GranteeCapabilities[] }) {
  return (
    permissions.granteeCapabilities?.flatMap((e) =>
      e.capabilities.capability.map((capability) => ({
        groupId: e.group?.id,
        userId: e.user?.id,
        capabilityName: capability.name,
        capabilityMode: capability.mode,
      })),
    ) ?? []
  );
}
