import React from 'react';
import { useForm } from 'react-hook-form';
import type { CustomView } from '../tableau';
import type { CustomViewsActions } from '../toolbar';

export interface AddViewProps {
  actions: CustomViewsActions;
}

type Inputs = Pick<CustomView, 'name'>;

export function AddView({ actions }: AddViewProps) {
  const { register, handleSubmit, reset } = useForm<Inputs>();

  const handleAdd = handleSubmit(async (data) => {
    reset();
    await actions.add(data.name);
  });

  return (
    <form className='add-view'>
      <h1>Add View</h1>
      <div className='flex gap-4'>
        <input
          {...register('name', { required: 'Name is required.' })}
          className='grow'
          placeholder='View Name'
          autoComplete='none'
        />
        <button className='theme-main px-4' type='submit' onClick={handleAdd}>
          Add
        </button>
      </div>
    </form>
  );
}
