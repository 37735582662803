import { ResponseType } from 'axios';
import {
  PaginatedOutput,
  PathParameterInput,
  stringifyPathParameters,
  TableauCommand,
} from './command';
import { View } from './view';

const METHOD_PATH = 'workbooks';

export interface Workbook {
  id: string;
  name: string;
  description: string;
  contentUrl: string;
  webpageUrl: string;
  size: string;
  defaultViewId: string;
  createdAt: string;
  updatedAt: string;
  showTabs: string;
  encryptExtracts: string;
  project: {
    id: string;
    name: string;
  };
  owner: {
    id: string;
  };
  tags: string[];
  views: {
    id: string;
    contentUrl: string;
    tags: string[];
  }[];
}

export interface GetWorkbookCommandInput {
  workbookId: string;
}

export interface GetWorkbookCommandOutput {
  workbook: Workbook;
}

export class GetWorkbookCommand implements TableauCommand<undefined, GetWorkbookCommandOutput> {
  method = 'GET';
  path: string;
  response?: GetWorkbookCommandOutput;

  constructor(input: GetWorkbookCommandInput) {
    this.path = `${METHOD_PATH}/${input.workbookId}`;
  }
}

export interface ListWorkbooksCommandInput extends PathParameterInput {}

export interface ListWorkbooksCommandOutput extends PaginatedOutput {
  workbooks: { workbook?: Workbook[] };
}

export class ListWorkbooksCommand implements TableauCommand<undefined, ListWorkbooksCommandOutput> {
  method = 'GET';
  path: string;
  response?: ListWorkbooksCommandOutput;

  constructor(input?: ListWorkbooksCommandInput) {
    const parameters = stringifyPathParameters(input);
    this.path = `${METHOD_PATH}${parameters}`;
  }
}

export interface ListViewsForWorkbookCommandInput {
  workbookId: string;
  includeUsageStatistics?: boolean;
}

export interface ListViewsForWorkbookCommandOutput extends PaginatedOutput {
  views: { view?: View[] };
}

export class ListViewsForWorkbookCommand
  implements TableauCommand<undefined, ListViewsForWorkbookCommandOutput>
{
  method = 'GET';
  path: string;
  response?: ListViewsForWorkbookCommandOutput;

  constructor(input: ListViewsForWorkbookCommandInput) {
    const usageStatistics = input.includeUsageStatistics ? '?includeUsageStatistics=true' : '';
    this.path = `${METHOD_PATH}/${input.workbookId}/views${usageStatistics}`;
  }
}

export interface GetViewPreviewImageCommandInput {
  workbookId: string;
  viewId: string;
}

export type GetViewPreviewImageCommandOutput = any;

export class GetViewPreviewImageCommand
  implements TableauCommand<undefined, GetViewPreviewImageCommandOutput>
{
  method = 'GET';
  path: string;
  response?: GetViewPreviewImageCommandOutput;
  responseType = 'blob' as ResponseType;

  constructor(input: GetViewPreviewImageCommandInput) {
    this.path = `${METHOD_PATH}/${input.workbookId}/views/${input.viewId}/previewImage`;
  }
}
