import React from 'react';
import ViewList from '~/components/ViewList';

export default function Home() {
  return (
    <div id='home' className='p-6'>
      <h1 className='text-4xl mb-6'>Welcome to Loch Analytics!</h1>
      <p className='mb-8'>
        Pick a dashboard below to start your data-driven decision-making journey. Loch's dashboards
        are customized to your industry and designed to highlight a variety of business processes,
        so you can easily see how your organization is performing, eliminating the need to make
        business decisions based on guess or gut instinct.
      </p>
      <ViewList />
    </div>
  );
}
