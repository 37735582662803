import React from 'react';
import { useConfig } from '@kikoda/generated-config-hooks';
import { Player } from '@lottiefiles/react-lottie-player';
import { Route, Switch } from 'react-router-dom';
import { ApiProvider, useLoadApi } from '~/hooks/apiProvider';
import { TableauProvider, useLoadTableau } from '~/hooks/tableauProvider';
import { useTenant } from '~/hooks/tenantProvider';
import { useUser } from '~/hooks/useUser';
import logo from '~/images/animated-nessi-login.json';
import name from '~/images/name.svg';
import { PublicRoutePath, RoutePath } from '~/route-path';
import { Login } from './login';
import { Register } from './register';
import { Reset } from './reset';
import type { WebConfig } from '~/web-config';

import './styles.scss';

type Props = {
  children: React.ReactNode;
};

export function Authenticator({ children }: Props) {
  const config = useConfig<WebConfig>();
  const { user } = useUser();
  const player = React.useRef<Player>(null);
  const { allowSelfRegistration } = useTenant();
  const { tableau } = useLoadTableau(config, user?.getUsername());
  const { api } = useLoadApi(config, user?.getUsername());

  const handlePlayAnimation = () => {
    player.current?.stop();
    player.current?.play();
  };

  if (user) {
    return (
      <TableauProvider value={tableau}>
        <ApiProvider value={api}> {children}</ApiProvider>
      </TableauProvider>
    );
  }

  return (
    <main id='authenticator'>
      <section className='visual'>
        <div className='logos'>
          <button className='logo' onClick={handlePlayAnimation}>
            <Player src={logo} ref={player} autoplay keepLastFrame />
          </button>
          <img src={name} alt='loch analytics' width={300} />
        </div>
      </section>
      <section className='panel'>
        <Switch>
          {allowSelfRegistration && (
            <Route path={PublicRoutePath.Register}>
              <Register />
            </Route>
          )}
          <Route path={PublicRoutePath.Recover}>
            <Reset />
          </Route>
          <Route path={RoutePath.All}>
            <Login />
          </Route>
        </Switch>
      </section>
    </main>
  );
}
