import { useContext } from 'react';
import { TableauClient } from '~/common/tableau';
import context from './context';

const useTableau = (): TableauClient | undefined => {
  const tableau = useContext(context);

  return tableau;
};

export default useTableau;
