import { createContext } from 'react';

export interface Tenant {
  found: boolean;
  ready: boolean;
  isLoading: boolean;
  subDomain?: string;
  id?: string;
  name?: string;
  status?: string;
  authType?: string;
  userPoolId?: string;
  appClientId?: string;
  industryId?: string;
  industryName?: string;
  stage?: string;
  allowSelfRegistration?: boolean;
  style?: TenantStyle;
}

export interface TenantStyle {
  colorMain?: string;
  colorContrast?: string;
  colorLight?: string;
  colorDark?: string;
}

const context = createContext<Tenant>({
  found: false,
  ready: true,
  isLoading: true,
});

const { Provider, Consumer } = context;

export { Provider, Consumer };
export default context;
