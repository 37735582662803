import { useState, useEffect } from 'react';
import { useConfig } from '@kikoda/generated-config-hooks';
import axios from 'axios';
import { Api } from '~/common/utils/api';
import { WebConfig } from '~/web-config';
import { useUser } from '../useUser';

export interface TenantUsers {
  id: string;
  email: string;
  tableauUserId?: string;
  tableauLicenseStatus: string;
  fullName: string;
  name?: string;
  siteRole?: string;
  lastLogin?: string;
  externalAuthUserId?: string;
  authSetting?: string;
  language?: string;
  locale?: string;
  domain?: {
    name?: string;
  };
}

export interface GetTenantUsersResponse {
  users: TenantUsers[];
}

async function loadGetTenantUsers(token: any, apiEndpoint: string): Promise<TenantUsers[]> {
  let users: TenantUsers[] = [];
  if (!token) return users;

  try {
    const api = axios.create();

    const response = await api.get<GetTenantUsersResponse>(`${apiEndpoint}/portal/users`, {
      headers: {
        Authorization: token,
      },
    });
    users = response.data.users;
  } catch (error) {
    console.debug('Error getting tenant users', error);
  } finally {
    return users;
  }
}

export const useGetTenantUsers = () => {
  const config = useConfig<WebConfig>();
  const [users, setUsers] = useState<TenantUsers[]>([]);
  const { user } = useUser();
  const { tenantServiceEndpoint } = config?.additionalConfig?.platform?.backend;
  useEffect(() => {
    const getTenantUsers = async () => {
      const users = await loadGetTenantUsers(Api.getToken(user), tenantServiceEndpoint);
      setUsers(users);
    };
    getTenantUsers();
  }, [tenantServiceEndpoint, user]);
  return { users };
};
