import { useEffect } from 'react';
import type { TenantStyle } from './tenantProvider';

export function useSetTheme({
  colorMain,
  colorContrast,
  colorLight,
  colorDark,
}: TenantStyle | undefined = {}) {
  useEffect(() => {
    if (colorMain) {
      document.body.style.setProperty('--color-main', colorMain);
    }
    if (colorContrast) {
      document.body.style.setProperty('--color-contrast', colorContrast);
    }
    if (colorLight) {
      document.body.style.setProperty('--color-light', colorLight);
    }
    if (colorDark) {
      document.body.style.setProperty('--color-dark', colorDark);
    }
  }, [colorContrast, colorDark, colorLight, colorMain]);
}
