import { useContext } from 'react';
import { ApiClient } from '~/common/api-client';
import context from './context';

const useApi = (): ApiClient | undefined => {
  const api = useContext(context);

  return api;
};

export default useApi;
