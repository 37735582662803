import {
  AddCommentCommand,
  AddCommentCommandInput,
  ApiClient,
  Comment,
  DeleteCommentCommand,
  GetCommentsCommand,
  LikeCommentCommand,
  UnlikeCommentCommand,
  UpdateCommentCommand,
} from '~/common';
import { APIResponse } from '~/utils/network';

export const getCommentItems = async (
  apiClient: ApiClient,
  viewId: string,
): Promise<APIResponse<Comment[]>> => {
  const apiCommand = new GetCommentsCommand({ viewId });

  const response = (await apiClient.send(apiCommand)) ?? [];
  return { data: response, error: undefined };
};

export const addCommentItem = async (
  apiClient: ApiClient,
  comment: AddCommentCommandInput,
): Promise<APIResponse<string>> => {
  try {
    const apiCommand = new AddCommentCommand(comment);
    const output = await apiClient.send(apiCommand);
    return { data: output.id, error: undefined };
  } catch (error: any) {
    return { error: error.message as string, data: undefined };
  }
};

export const updateCommentItem = async (
  apiClient: ApiClient,
  comment: Comment,
): Promise<APIResponse<Comment>> => {
  const apiCommand = new UpdateCommentCommand(comment);
  apiCommand.data = comment;
  const response = await apiClient.send(apiCommand);
  return { data: response, error: undefined };
};

export const deleteComment = async (
  apiClient: ApiClient,
  viewId: string,
  commentId: string,
): Promise<APIResponse<boolean>> => {
  try {
    const apiCommand = new DeleteCommentCommand({ viewId, commentId });
    apiClient.send(apiCommand);
    return {
      data: true,
      error: undefined,
    };
  } catch (error: any) {
    return { error: error.message as string, data: undefined };
  }
};

export const likeCommentItem = async (
  apiClient: ApiClient,
  viewId: string,
  commentId: string,
): Promise<APIResponse<boolean>> => {
  try {
    const apiCommand = new LikeCommentCommand({ viewId, commentId });
    apiClient.send(apiCommand);
    return { data: true, error: undefined };
  } catch (error: any) {
    return { error: error.message as string, data: undefined };
  }
};

export const unlikeCommentItem = async (
  apiClient: ApiClient,
  viewId: string,
  commentId: string,
): Promise<APIResponse<boolean>> => {
  try {
    const apiCommand = new UnlikeCommentCommand({ viewId, commentId });
    apiClient.send(apiCommand);
    return { data: true, error: undefined };
  } catch (error: any) {
    return { error: error.message as string, data: undefined };
  }
};
