import { ResponseType } from 'axios';

export interface TableauCommand<Input, Output = Record<string, never>> {
  method: string;
  path: string;
  data?: Input;
  response?: Output;
  responseType?: ResponseType;
}

export type FilterOperators = 'eq' | 'cieq' | 'gt' | 'gte' | 'lt' | 'lte' | 'in';

export interface PathParameterInput<Filter extends string = FilterOperators> {
  /**
   * A filter expression that can be used to filter the list returned.
   *
   * @format field:operator:value
   * @example name:eq:Name
   */
  filter?: `${string}:${Filter}:${string}`;
  /**
   * The number of items to return in one response. The minimum is 1. The maximum is 1000. The default is 100.
   */
  pageSize?: number;
  /**
   * The offset for paging. The default is 1.
   */
  pageNumber?: number;
}

export interface PaginatedOutput {
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalAvailable: number;
  };
}

export function stringifyPathParameters(parameters: PathParameterInput | undefined) {
  const joined = [
    parameters?.filter ? `filter=${parameters.filter}` : '',
    parameters?.pageSize ? `pageSize=${parameters.pageSize}` : '',
    parameters?.pageNumber ? `pageNumber=${parameters.pageNumber}` : '',
  ]
    .filter(Boolean)
    .join('&');

  return joined ? `?${joined}` : '';
}

export enum SiteRole {
  Creator = 'Creator',
  Explorer = 'Explorer',
  ExplorerCanPublish = 'ExplorerCanPublish',
  SiteAdministratorExplorer = 'SiteAdministratorExplorer',
  SiteAdministratorCreator = 'SiteAdministratorCreator',
  Unlicensed = 'Unlicensed',
  Viewer = 'Viewer',
}

export enum AuthSetting {
  ServerDefault = 'ServerDefault',
  SAML = 'SAML',
  OpenID = 'OpenID',
  TableauIDWithMFA = 'TableauIDWithMFA',
}
