import axios from 'axios';
import { ITableauCredential, TableauAuthenticationResponse } from './models/tableau-credentials';
import { ITableauToken } from './models/tableau-token';

export class TableauJwt implements ITableauCredential {
  static async fromEncodedToken(token: ITableauToken): Promise<TableauJwt> {
    return new TableauJwt(token);
  }

  constructor(
    /** The JWT token to use for authentication as an encoded token. */
    public readonly token: ITableauToken,
  ) {}

  authenticate = async (endpoint: string, contentUrl: string) => {
    console.debug('Authenticating with Tableau using a JWT', {
      contentUrl,
      endpoint,
    });

    // Get the token, refreshing if necessary.
    const token = await this.token.token();
    const response = await axios.post<TableauAuthenticationResponse>(
      `${endpoint}/auth/signin`,
      {
        credentials: {
          site: {
            contentUrl,
          },
          jwt: token,
        },
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    // JWT Auth signin doesn't return an estimated time to expiration, so we'll just set it to 5 mins
    response.data.credentials.estimatedTimeToExpiration = '00:05:00';

    return response.data;
  };
}
