import React from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as CheckOutline } from '~/images/check-outline.svg';
import { ReactComponent as CheckSolid } from '~/images/check-solid.svg';
import { ReactComponent as Delete } from '~/images/delete.svg';
import { ReactComponent as Edit } from '~/images/edit.svg';
import type { CustomView } from '../tableau';
import type { CustomViewsActions } from '../toolbar';

export interface ViewProps {
  customView: CustomView;
  activeCustomView: CustomView | undefined;
  actions: CustomViewsActions;
}

type Inputs = Pick<CustomView, 'name'>;

export function View({ customView, activeCustomView, actions }: ViewProps) {
  const { register, handleSubmit } = useForm<Inputs>();
  const [editing, setEditing] = React.useState(false);

  const handleSwitchView = async () => {
    await actions.show(customView);
  };

  const handleUpdate = handleSubmit(async (data) => {
    setEditing(false);
    await actions.update(customView, data);
  });

  const handleDelete = async () => {
    await actions.delete(customView);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // Apply edits on enter.
      handleUpdate();
    } else if (event.key === 'Escape') {
      // Cancel edits on escape.
      setEditing(false);
    }
  };

  const toggleEditing = React.useCallback(() => {
    setEditing((e) => !e);
  }, []);

  const isActiveCustomView = activeCustomView?.url === customView.url;

  return (
    <li className='flex'>
      <button onClick={handleSwitchView} title='Switch to this view'>
        {isActiveCustomView ? <CheckSolid width={16} /> : <CheckOutline width={16} />}
      </button>

      <div className='text-black font-semibold grow'>
        {editing ? (
          <input
            {...register('name', { required: 'Name is required.' })}
            className='w-full'
            autoFocus
            defaultValue={customView.name}
            onBlur={handleUpdate}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <button className='w-full' onClick={handleSwitchView}>
            {customView.name}
            {customView.default && ' (default)'}
          </button>
        )}
      </div>

      {/* customView.shared is used as a proxy for ownership. */}
      {!customView.shared && (
        <>
          <button onClick={toggleEditing} title='Edit view name'>
            <Edit width={16} />
          </button>
          <button onClick={handleDelete} title='Delete view'>
            <Delete width={16} />
          </button>
        </>
      )}
    </li>
  );
}
