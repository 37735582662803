import React from 'react';
import { useConfig } from '@kikoda/generated-config-hooks';
import { CircularProgress } from '@mui/material';
import { useGetTableauToken } from '~/hooks/tableauProvider';
import colors from '~/sass/colors.scss';
import { WebConfig } from '~/web-config';
import { Tableau } from '../Workbook/View/tableau';

export default function TenantUsage(): JSX.Element {
  const {
    additionalConfig: { tableauEndpoint },
  } = useConfig<WebConfig>();
  const src = `${tableauEndpoint}/views/TenantAdminInsights/TenantAdminInsights`;

  const { token, isLoading } = useGetTableauToken(src);
  if (isLoading) {
    return (
      <div id='workbook-view' className='loader'>
        <CircularProgress />
      </div>
    );
  }

  if (token === '-' || !token) {
    return (
      <div id='workbook-view' className='loader'>
        <span>Unable to load workbook. Tableau Authentication unavailable.</span>
      </div>
    );
  }

  return (
    <div id='workbook-view'>
      {/* Drawing outside the box */}
      <div className='outside' style={{ backgroundColor: colors.rootBackground }} />
      <div className='container' style={{ backgroundColor: colors.rootBackground }}>
        <div className='view-container'>
          <div className='view'>
            <Tableau src={src} token={token} hide-tabs toolbar='hidden' />
          </div>
        </div>
      </div>
    </div>
  );
}
