import { Mutex } from 'async-mutex';
import axios from 'axios';
import { throwError } from '../../utils/throw-error';
import { TableauCommand } from './models/command';
import { ITableauCredential } from './models/tableau-credentials';
import { PersonalAccessToken } from './personal-access-token';
import { TableauJwt } from './tableau-jwt';

const TABLEAU_API_URI = 'api/3.18';

export interface TableauClientOptions {
  endpoint?: string;
  siteName?: string;
  credentials?: ITableauCredential;
  specifier?: string;
}

export class TableauClient {
  public readonly endpoint: string;
  private credentials?: ITableauCredential;
  private contentUrl: string;
  private siteId = '';
  private token?: string | null;
  private tokenExpiration?: Date;
  private specifier?: string;
  private mutex: Mutex;

  constructor(options?: TableauClientOptions) {
    // if the endpoint is not provided, try to find it in the environment
    const tableauEndpoint =
      options?.endpoint ??
      process.env.TABLEAU_ENDPOINT ??
      throwError(
        new Error('Invalid configuration: TABLEAU_ENDPOINT is required but was not provided.'),
      );

    const tableauSiteName =
      options?.siteName ??
      process.env.TABLEAU_SITE_NAME ??
      throwError(
        new Error('Invalid configuration: TABLEAU_SITE_NAME is required but was not provided.'),
      );

    // create a mutex to ensure that only one request is authenticating at a time
    this.mutex = new Mutex();

    this.endpoint = `${tableauEndpoint}/${TABLEAU_API_URI}`;
    this.contentUrl = tableauSiteName;
    this.credentials = options?.credentials;
    this.specifier = options?.specifier;

    // if we're in the browser, we'll look in local storage for the credentials
    if (typeof window !== 'undefined') {
      console.debug('Looking for existing Tableau auth token in local storage...');
      this.token = localStorage.getItem(this.keyAuthToken());
      this.tokenExpiration = new Date(localStorage.getItem(this.keyAuthTokenExpiration()) ?? '');
      this.siteId = localStorage.getItem(this.keySiteId()) ?? '';

      if (this.token) {
        console.debug('Found existing Tableau auth token in local storage.');
      }
    }
  }

  authenticate = async () => {
    if (this.token && !this.expired()) {
      console.debug('Using existing Tableau auth token.');
      return;
    } else {
      console.debug(
        'Existing Tableau auth token has expired or is missing. Authenticating with Tableau...',
      );
    }
    if (!this.credentials) {
      // if we don't already have credentials, try to find them in the environment/secrets manager
      this.credentials = await PersonalAccessToken.fromAwsSecretsManager();
    }

    try {
      const auth = await this.credentials.authenticate(this.endpoint, this.contentUrl);

      this.siteId = auth.credentials.site.id;
      this.token = auth.credentials.token;

      // Convert the expiration time to milliseconds and add it to the current time
      // to get the expiration time in milliseconds
      const [hours, minutes, seconds] = auth.credentials.estimatedTimeToExpiration.split(':');
      const timeToExpiration = Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
      this.tokenExpiration = new Date(Date.now() + timeToExpiration * 1000);

      // if we're in the browser, we'll save the token in local storage
      if (typeof window !== 'undefined') {
        localStorage.setItem(this.keyAuthToken(), this.token);
        localStorage.setItem(this.keyAuthTokenExpiration(), this.tokenExpiration?.toISOString());
        localStorage.setItem(this.keySiteId(), this.siteId);
      }
    } catch (error: any) {
      console.error('Tableau authentication failed:', error);
      if (axios.isAxiosError(error)) {
        const err = {
          code: error.response?.status,
          message: error.response?.statusText,
        };
        if (err.code === 401) {
          throw new Error(`Tableau authentication failed ${err.message}. Please contact support.`);
        } else {
          throw new Error(
            `Tableau authentication failed: (${err.code}) ${err.message}. Please contact support.`,
          );
        }
      } else {
        throw error;
      }
    }
  };

  send = async <InputType extends object, OutputType>(
    command: TableauCommand<InputType, OutputType>,
  ) => {
    console.debug(`Sending request to ${this.endpoint}/sites/${this.siteId}/${command.path}...`);
    // make sure we have a valid token before sending the request
    await this.mutex.runExclusive(this.authenticate);

    // send the request
    try {
      const response = await axios({
        method: command.method,
        url: `${this.endpoint}/sites/${this.siteId}/${command.path}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Tableau-Auth': this.token!,
        },
        data: command.data,
        responseType: command.responseType,
      });

      return response.data as OutputType;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const err = {
          code: error.code,
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data,
        };
        console.error('[TableauClient::send] Error:', err);
      } else {
        console.error('[TableauClient::send] Error:', error);
      }
      // if (error.response?.status === 401) {
      //   // if we get a 401
      //   // clear the token and expiration
      //   this.token = null;
      //   this.tokenExpiration = undefined;
      //   // if we're in the browser, we'll clear the token from local storage
      //   if (typeof window !== 'undefined') {
      //     localStorage.removeItem(this.keyAuthToken());
      //     localStorage.removeItem(this.keyAuthTokenExpiration());
      //   }
      //   console.error(
      //     'Tableau authentication token has expired. Please re-authenticate with Tableau.',
      //   );

      //   throw new Error('Tableau authentication has failed.');
      // }
      throw error;
    }
  };

  jwt = async () => {
    if (!(this.credentials instanceof TableauJwt)) {
      return null;
    }

    const token = await this.credentials.token.token();
    return token;
  };

  private expired = () => {
    if (!this.tokenExpiration) {
      return true;
    }

    return Date.now() > this.tokenExpiration.getTime();
  };

  private keyAuthToken = () => this.key('tableau.authToken', this.specifier);
  private keyAuthTokenExpiration = () => this.key('tableau.authTokenExpiration', this.specifier);
  private keySiteId = () => this.key('tableau.siteId', this.specifier);
  private key = (key: string, id?: string) => (id ? `${key}.${id}` : key);
}
