import React from 'react';
import { CircularProgress } from '@mui/material';

export default ({ message = 'Loading' }: { message?: string }) => {
  return (
    <div>
      <div>
        <CircularProgress />
      </div>
      <div>{message}</div>
    </div>
  );
};
