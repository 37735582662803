import React from 'react';
import { ApiClient } from '~/common';
import { WebConfig } from '~/web-config';
import { useUser } from '../useUser';

export function useLoadApi(config: WebConfig, userId: string | undefined) {
  const [api, setApi] = React.useState<ApiClient>();
  const { endpoint } = config.additionalConfig.platform.backend;
  const { user } = useUser();

  React.useEffect(() => {
    if (!userId) {
      // Reset tableau state to undefined.
      setApi(undefined);
      return;
    }

    const session = user?.getSignInUserSession();
    const apiClient = new ApiClient({
      endpoint: endpoint,
      token: session?.getIdToken()?.getJwtToken(),
    });

    setApi(apiClient);
  }, [endpoint, user, userId]);

  return { api };
}
