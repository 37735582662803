import React from 'react';
import { View } from './view';
import type { CustomView } from '../tableau';
import type { CustomViewsActions } from '../toolbar';

export interface ActiveViewProps {
  activeCustomView: CustomView;
  actions: CustomViewsActions;
}

export function ActiveView({ activeCustomView, actions }: ActiveViewProps) {
  const handleSaveView = async () => {
    await actions.save(activeCustomView);
  };

  return (
    <div>
      <h1>Active View</h1>
      <div className='flex'>
        <div className='grow'>
          <View
            customView={activeCustomView}
            activeCustomView={activeCustomView}
            actions={actions}
          />
        </div>
        <button className='theme-main px-4' onClick={handleSaveView} title='Save view state'>
          Save
        </button>
      </div>
    </div>
  );
}
