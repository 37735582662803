import React from 'react';

export interface MessageProps {
  children: React.ReactNode;
}

const Message: React.FC<MessageProps> = ({ children }) => {
  return (
    <div
      style={{
        width: '20vw',
        margin: '30vh auto',
        textAlign: 'center',
      }}>
      <h3>{children}</h3>
    </div>
  );
};

export default Message;
