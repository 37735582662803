import React from 'react';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useTenant } from '~/hooks/tenantProvider';
import { LochUser } from '~/hooks/useUser';
import { AuthenticatedRoutePath, PublicRoutePath } from '~/route-path';
import * as validation from '~/utils/validation';

import './form.scss';

type Inputs = {
  email: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;
};

enum Mode {
  Login,
  NewPassword,
}

export function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ mode: 'onTouched' });
  const [error, setError] = React.useState('');
  const [user, setUser] = React.useState<LochUser>();
  const [mode, setMode] = React.useState(Mode.Login);

  const { allowSelfRegistration } = useTenant();
  const history = useHistory();
  const location = useLocation();

  const handleLogin = handleSubmit(async (data) => {
    setError('');
    try {
      const user: LochUser = await Auth.signIn(data.email, data.password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(user);
        setMode(Mode.NewPassword);
        return;
      }

      // if we're on a public route path, go home
      if (PublicRoutePath.matchAny(location.pathname)) {
        history.push(AuthenticatedRoutePath.Home);
        return;
      }

      // if we're on a matching authenticated route go there
      if (AuthenticatedRoutePath.matchAny(location.pathname)) {
        history.push(location.pathname);
        return;
      }

      // otherwise go home
      history.push(AuthenticatedRoutePath.Home);
      return;
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Not authorized.';
      setError(`Unable to login: ${message}`);
    }
  });

  const handleSetPassword = handleSubmit(async (data) => {
    setError('');
    try {
      await Auth.completeNewPassword(user, data.newPassword);
      history.push(AuthenticatedRoutePath.Home);
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Set new password request failed.';
      setError(`Unable to set new password: ${message}`);
    }
  });

  return (
    <>
      {mode === Mode.Login && (
        <form id='authenticator-form' onSubmit={handleLogin}>
          <h1>Login</h1>
          <input
            {...register('email', { required: 'Email is required.' })}
            placeholder='Email'
            autoComplete='email'
          />
          {errors.email && <div className='error'>{errors.email.message}</div>}
          <input
            {...register('password', { required: 'Password is required.' })}
            placeholder='Password'
            type='password'
            autoComplete='current-password'
          />
          {errors.password && <div className='error'>{errors.password.message}</div>}
          {error && <div className='error'>{error}</div>}
          <div className='actions'>
            <input className='theme-main' type='submit' value='Login' />
            <input
              className={'theme-inverse outlined' + (allowSelfRegistration ? '' : ' disabled')}
              type='button'
              value='Register'
              onClick={() => history.push(PublicRoutePath.Register)}
              disabled={!allowSelfRegistration}
              title={!allowSelfRegistration ? 'Registration is disabled.' : ''}
            />
          </div>
          <div className='secondary-actions'>
            <input
              className='color-main text'
              type='button'
              value='Forgot Password?'
              onClick={() => history.push(PublicRoutePath.Recover)}
            />
          </div>
        </form>
      )}
      {mode === Mode.NewPassword && (
        <form id='authenticator-form' onSubmit={handleSetPassword}>
          <h1>Set a new password</h1>
          <input
            {...register('email')}
            placeholder='Email'
            type='email'
            autoComplete='email'
            hidden
          />
          <input
            {...register('newPassword', {
              required: 'Password is required.',
              pattern: {
                value: validation.password,
                message:
                  'Password must be at least 8 characters long with at least one lowercase letter, uppercase letter, special character, and number.',
              },
            })}
            placeholder='New Password'
            type='password'
            autoComplete='new-password'
          />
          {errors.newPassword && <div className='error'>{errors.newPassword.message}</div>}
          <input
            {...register('confirmNewPassword', {
              validate: (value, inputs) => value === inputs.newPassword || 'Passwords must match.',
            })}
            placeholder='Confirm Password'
            type='password'
            autoComplete='new-password'
          />
          {errors.confirmNewPassword && (
            <div className='error'>{errors.confirmNewPassword.message}</div>
          )}
          {error && <div className='error'>{error}</div>}
          <div className='actions'>
            <input className='theme-main' type='submit' value='Login' />
          </div>
        </form>
      )}
    </>
  );
}
