import React from 'react';
import { TableauClient, TableauJwt } from '~/common/tableau';
import { ConnectedAppToken } from './connected-app-token';
import type { WebConfig } from '~/web-config';

export function useLoadTableau(config: WebConfig, userId: string | undefined) {
  const [tableau, setTableau] = React.useState<TableauClient>();

  const { endpoint, tenantServiceEndpoint } = config.additionalConfig.platform.backend;

  React.useEffect(() => {
    if (!userId) {
      // Reset tableau state to undefined.
      setTableau(undefined);
      return;
    }

    const tableauClient = new TableauClient({
      credentials: new TableauJwt(new ConnectedAppToken(tenantServiceEndpoint, userId)),
      endpoint: endpoint.replace('/api', ''),
      siteName: 'kikoda',
      specifier: userId,
    });

    setTableau(tableauClient);
  }, [endpoint, tenantServiceEndpoint, userId]);

  return { tableau };
}
