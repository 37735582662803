import {
  AuthSetting,
  PaginatedOutput,
  PathParameterInput,
  SiteRole,
  stringifyPathParameters,
  TableauCommand,
} from './command';

const METHOD_PATH = 'users';

export interface User {
  id: string;
  email: string;
  fullName: string;
  name: string;
  siteRole: string;
  lastLogin: string;
  externalAuthUserId: string;
  authSetting: string;
  language: string;
  locale: string;
  domain: {
    name: string;
  };
}

export interface CreateUserCommandInput {
  user: {
    name: string;
    siteRole: SiteRole;
    authSetting?: AuthSetting;
  };
}

export interface CreateUserCommandOutput {
  user: {
    id: string;
    name: string;
    siteRole: string;
    authSetting: string;
  };
}

export class CreateUserCommand
  implements TableauCommand<CreateUserCommandInput, CreateUserCommandOutput>
{
  method = 'POST';
  path = METHOD_PATH;

  constructor(public data: CreateUserCommandInput, public response?: CreateUserCommandOutput) {}
}

export interface DeleteUserCommandInput {
  userId: string;
}

export class DeleteUserCommand implements TableauCommand<DeleteUserCommandInput> {
  method = 'DELETE';
  path: string;

  constructor(public data: DeleteUserCommandInput) {
    this.path = `${METHOD_PATH}/${this.data.userId}`;
  }
}

export interface ListUsersCommandInput extends PathParameterInput {}

export interface ListUsersCommandOutput extends PaginatedOutput {
  users: { user?: User[] };
}

export class ListUsersCommand implements TableauCommand<undefined, ListUsersCommandOutput> {
  method = 'GET';
  path: string;
  response?: ListUsersCommandOutput;

  constructor(input?: ListUsersCommandInput) {
    const parameters = stringifyPathParameters(input);
    this.path = `${METHOD_PATH}${parameters}`;
  }
}
