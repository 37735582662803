import { useConfig } from '@kikoda/generated-config-hooks';
import axios from 'axios';
import { Api } from '~/common/utils/api';
import { WebConfig } from '~/web-config';
import { useUser } from '../useUser';

export interface DeleteUser {
  userId?: string;
}

async function deleteUsers(token: any, apiEndpoint: string, users: string[]): Promise<void> {
  if (!token) return;
  try {
    const api = axios.create();
    await api.post(
      `${apiEndpoint}/portal/users/delete`,
      {
        users: users,
      },
      {
        headers: {
          Authorization: token,
        },
      },
    );
  } catch (error) {
    console.debug('Error deleting user', error);
  } finally {
    return;
  }
}

export const useDeleteTenantUsers = () => {
  const config = useConfig<WebConfig>();
  const { user } = useUser();
  const { tenantServiceEndpoint } = config?.additionalConfig?.platform?.backend;

  const deleteTenantUsers = async (users: string[]) => {
    await deleteUsers(Api.getToken(user), tenantServiceEndpoint, users);
  };

  return { deleteTenantUsers };
};
