import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { useUser } from '~/hooks/useUser';

interface Inputs {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

export default function PasswordUpdateDialog() {
  const [open, setOpen] = React.useState(false);
  const { user } = useUser();
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm<Inputs>({
    mode: 'onTouched',
  });
  const onConfirm = handleSubmit(async (data) => {
    await Auth.changePassword(user, data.currentPassword, data.password);
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (confirmed: boolean) => {
    setOpen(false);
    if (confirmed) {
      onConfirm();
    }
  };

  return (
    <>
      <Button
        className='theme-inverse outlined'
        type='button'
        startIcon={<RefreshIcon />}
        onClick={handleClickOpen}>
        <Typography>Reset Password</Typography>
      </Button>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form id='password-reset-form'>
              <Grid container spacing={2} style={{ padding: 5 }}>
                <Grid item xs={12}>
                  <label htmlFor='password'>Current Password</label>
                  <TextField
                    id='password'
                    {...register('currentPassword', {
                      required: 'Current Password is required.',
                    })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <label htmlFor='newpassword'>New Password</label>
                  <TextField
                    id='newpassword'
                    type='newpassword'
                    {...register('password', {
                      required: 'Password is required.',
                    })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <label htmlFor='confirmPassword'>Confirm Password</label>
                  <TextField
                    id='confirmPassword'
                    type='password'
                    {...register('confirmPassword', {
                      required: 'Password is required.',
                    })}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant='outlined' color='success' onClick={() => handleClose(true)}>
            Reset Password
          </Button>
          <Button variant='outlined' color='error' onClick={() => handleClose(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
