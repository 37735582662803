import React, { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import LochLogoDryIcon from '~/elements/Icons/LochLogoDry';
import { useAddTenantUser } from '~/hooks/tenantPortal/useAddTenantUser';
import { useDeleteTenantUsers } from '~/hooks/tenantPortal/useDeleteTenantUsers';
import { useGetTenantProfile } from '~/hooks/tenantPortal/useGetTenantProfile';
import { useGetTenantUsers } from '~/hooks/tenantPortal/useGetTenantUsers';
import { useResetTenantUsersPw } from '~/hooks/tenantPortal/useResetTenantUsersPw';
import { useUser } from '~/hooks/useUser';
import './styles.scss';

type Inputs = {
  companyName: string;
  contact: {
    name: string;
    email: string;
    phone: string;
  };
};

const phoneFormat = (input?: string) => {
  if (!input) return '';
  if (input.length === 10) {
    return input.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  } else if (input.length < 10) {
    return 'was not supplied enough numbers please pass a 10 digit number';
  } else if (input.length > 10) {
    return 'was supplied too many numbers please pass a 10 digit number';
  } else {
    return 'something went wrong';
  }
};

export function AddUserDialog() {
  const [open, setOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const { addTenantUser } = useAddTenantUser();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleAddUser = async () => {
    const newUser = {
      givenName: firstName,
      familyName: lastName,
      userId: email,
    };
    await addTenantUser(newUser);
    setOpen(false);
  };
  return (
    <div>
      <Button
        startIcon={<PersonAddIcon />}
        color='primary'
        variant='text'
        type='button'
        onClick={handleClickOpen}>
        Add User
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a user, please enter the user's name and email address.
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='fname'
            label='First Name'
            type='text'
            fullWidth
            value={firstName}
            onChange={handleFirstNameChange}
          />
          <TextField
            autoFocus
            margin='dense'
            id='lname'
            label='Last Name'
            type='text'
            fullWidth
            value={lastName}
            onChange={handleLastNameChange}
          />
          <TextField
            autoFocus
            margin='dense'
            id='email'
            label='Email Address'
            type='email'
            fullWidth
            value={email}
            onChange={handleEmailChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddUser}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ConfirmDeleteDialog({ handleDelete }: { handleDelete: () => void }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (confirmed: boolean) => {
    setOpen(false);
    if (confirmed) {
      handleDelete();
    }
  };

  // Todo: display selected rows
  // const apiRef = useGridApiRef();
  // const [dataRows, setDataRows] = React.useState('0 rows selected');
  // useEffect(() => {
  //   if (apiRef?.current?.getSelectedRows) {
  //     const selectedRows = apiRef?.current?.getSelectedRows();
  //     const userIdArrayFromMap: string[] = [];
  //     selectedRows?.forEach((row: any) => {
  //       userIdArrayFromMap.push(row.email);
  //     });
  //     // if (userIdArrayFromMap.length === 0) return '0 rows selected';
  //     setDataRows(userIdArrayFromMap.map((row: any) => row.id).join(', '));
  //     console.log('dataRows', dataRows);
  //   }
  // }, [apiRef]);

  return (
    <div>
      <Button
        variant='outlined'
        type='button'
        color='error'
        startIcon={<DeleteIcon />}
        // disabled={dataRows === '0 rows selected'}
        onClick={handleClickOpen}>
        Delete User
      </Button>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the user(s)? This action cannot be undone.
            {/* <br />
            <br />
            {dataRows} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
          <Button onClick={() => handleClose(true)}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ConfirmResetPasswordDialog({
  apiRef,
  handleResetPassword,
}: {
  apiRef: React.MutableRefObject<any>;
  handleResetPassword: () => void;
}) {
  const [open, setOpen] = React.useState(false);
  const [dataRows, setDataRows] = React.useState<any[]>([]);
  useEffect(() => {
    const selectedRows = apiRef.current?.getSelectedRows();
    if (selectedRows) {
      setDataRows(selectedRows);
    }
  }, [apiRef]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (confirmed: boolean) => {
    setOpen(false);
    if (confirmed) {
      handleResetPassword();
    }
  };

  return (
    <div>
      <Button
        startIcon={<RefreshIcon />}
        variant='outlined'
        type='button'
        onClick={handleClickOpen}>
        Reset Password
      </Button>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>Reset User Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure? The user(s) will be notified via email with instructions on how to change
            their password. {dataRows.length}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
          <Button onClick={() => handleClose(true)}>Reset password</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function Tenant() {
  const { user } = useUser();
  const { profile } = useGetTenantProfile();
  const {
    register,
    // formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      companyName: '',
    },
    values: {
      companyName: profile?.name ?? '',
      contact: {
        name: `${profile?.contactFirstName ?? ''} ${profile?.contactLastName ?? ''}`,
        email: profile?.contactEmail ?? '',
        phone: phoneFormat(profile?.contactPhone),
      },
    },
    mode: 'onTouched',
  });

  const { users } = useGetTenantUsers();
  const { deleteTenantUsers } = useDeleteTenantUsers();
  const { resetTenantUsersPw } = useResetTenantUsersPw();
  const apiRef = useGridApiRef();
  const columns: GridColDef[] = [
    { field: 'fullName', headerName: 'User Name', flex: 0.75 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'tableauLicenseStatus', headerName: 'License Status', flex: 0.5 },
    { field: 'lastLogin', headerName: 'Last Login Date', flex: 0.5 },
  ];
  const handleDelete = async () => {
    const selectedRows = apiRef.current?.getSelectedRows();
    const userIdArrayFromMap: string[] = [];
    selectedRows?.forEach((row) => {
      userIdArrayFromMap.push(row.email);
    });
    await deleteTenantUsers(userIdArrayFromMap);
  };
  const handleResetPassword = async () => {
    const selectedRows = apiRef.current?.getSelectedRows();
    const userIdArrayFromMap: string[] = [];
    selectedRows?.forEach((row) => {
      userIdArrayFromMap.push(row.email);
    });
    await resetTenantUsersPw(userIdArrayFromMap);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '95%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 1,
      }}>
      <Grid container spacing={2} sx={{ height: '100%', p: 2 }}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              avatar={<LochLogoDryIcon style={{ height: 44, paddingRight: 14 }} />}
              title='My Loch'
            />
            <CardContent>
              <Grid container spacing={2} gap={3} sx={{ p: 2 }}>
                <Grid item xs={12}>
                  <Typography variant='h6' className='profile-label' sx={{ pb: 2, pl: 1 }}>
                    Company Name
                  </Typography>
                  <TextField
                    type='text'
                    {...register('companyName', {
                      required: 'Company Name is required.',
                    })}
                    placeholder='Company Name'
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h6' className='profile-label' sx={{ pb: 2, pl: 1 }}>
                    Contact Name
                  </Typography>
                  <TextField
                    type='text'
                    {...register('contact.name', {
                      required: 'Contact Name is required.',
                    })}
                    placeholder='Contact Name'
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h6' className='profile-label' sx={{ pb: 2, pl: 1 }}>
                    Contact Email
                  </Typography>
                  <TextField
                    type='text'
                    {...register('contact.email', {
                      required: 'Contact Email is required.',
                    })}
                    placeholder='Contact Email'
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h6' className='profile-label' sx={{ pb: 2, pl: 1 }}>
                    Contact Phone
                  </Typography>
                  <TextField
                    type='text'
                    {...register('contact.phone', {
                      required: 'Contact Phone is required.',
                    })}
                    placeholder='Contact Phone'
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader
              title='Users'
              avatar={<PeopleIcon />}
              action={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    minHeight: '55px',
                    m: 'auto',
                  }}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <AddUserDialog />
                    </Grid>
                    <Grid item>
                      <ConfirmResetPasswordDialog
                        apiRef={apiRef}
                        handleResetPassword={handleResetPassword}
                      />
                    </Grid>
                    <Grid item>
                      <ConfirmDeleteDialog handleDelete={handleDelete} />
                    </Grid>
                  </Grid>
                </Box>
              }
            />
            <CardContent>
              <DataGrid
                apiRef={apiRef}
                rows={[...users]}
                columns={columns}
                checkboxSelection
                isRowSelectable={(params) => params.row.email !== user?.attributes?.email}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
