import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTableau } from '~/hooks/tableauProvider';
import { useTenant } from '~/hooks/tenantProvider';
import { getViews, View } from './api';
import ViewThumb from './ViewThumb';

import './styles.scss';

export default function ViewList() {
  const tableauClient = useTableau();
  const tenant = useTenant();
  const [views, setViews] = useState(Array.from<View | undefined>({ length: 10 }));

  useEffect(() => {
    const loadViews = async () => {
      // Wait for tableauClient to load.
      if (!tableauClient) return;

      const { data = [], error } = await getViews(tableauClient, tenant);
      if (error) {
        toast(error, { toastId: 'tableau-error' });
      }
      setViews(data);
    };

    loadViews();
  }, [tableauClient, tenant]);

  return (
    <div id='view-list'>
      {views.map((view, index) => (
        <ViewThumb key={view?.id ?? index} view={view} />
      ))}
    </div>
  );
}
