import React from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from '~/hooks/useUser';
import { ReactComponent as Arrow } from '~/images/arrow.svg';
import { ProtectedRoutePath, AuthenticatedRoutePath } from '~/route-path';

export interface UserMenuItemData {
  text: string;
  path: string;
}

export interface UserMenuItemProps {
  text: string;
  path: string;
}

function UserMenuItem(props: UserMenuItemProps): JSX.Element {
  // Get the active workbook and view from the URL
  const location = useLocation();
  const active = location.pathname === props.path;
  return (
    <li id='menu-item' className={classNames('single', { active })}>
      <Link type='button' to={`${props.path}`}>
        {props.text}
      </Link>
    </li>
  );
}

interface UserMenuProps {
  onGoBack: () => void;
}
export default function UserMenu(props: UserMenuProps): JSX.Element {
  const menuItems = [
    { text: 'My Profile', path: AuthenticatedRoutePath.Profile },
  ] as UserMenuItemData[];
  const { isAdminUser } = useUser();
  if (isAdminUser) {
    menuItems.push({ text: 'My Loch', path: ProtectedRoutePath.Tenant });
    menuItems.push({ text: 'My Usage', path: ProtectedRoutePath.TenantUsage });
  }
  return (
    <section className='body'>
      {' '}
      <li id='menu-item' className={classNames('single')}>
        <button className='reverse-arrow' onClick={props.onGoBack}>
          {`Go Back`}
          <Arrow className='arrow z-10' />
        </button>
      </li>
      {menuItems.map((item, idx) => (
        <UserMenuItem key={idx.toString()} text={item.text} path={item.path} />
      ))}
    </section>
  );
}
