import { ApiCommand } from '../api-command';

export interface UserData {
  id: string;
  name: string;
}

export interface LikeComment {
  userId: string;
  name: string;
}

export interface Comment {
  tenandId: string;
  viewId: string;
  userId?: string;
  commentUser?: UserData;
  text: string;
  datePosted: Date;
  isEdited: boolean;
  id?: string;
  likes: LikeComment[];
}

export interface AddCommentCommandInput {
  viewId: string;
  text: string;
}

export interface AddCommentCommandOutput {
  id: string;
}

export class AddCommentCommand
  implements ApiCommand<AddCommentCommandInput, AddCommentCommandOutput>
{
  method = 'POST';
  path: string;

  constructor(public data: AddCommentCommandInput, public response?: AddCommentCommandOutput) {
    this.path = `views/${this.data.viewId}/comments`;
  }
}

export interface GetCommentsCommandInput {
  viewId: string;
}

export type GetCommentsCommandOutput = Comment[];

export class GetCommentsCommand implements ApiCommand<undefined, GetCommentsCommandOutput> {
  method = 'GET';
  path: string;

  constructor(input: GetCommentsCommandInput, public response?: GetCommentsCommandOutput) {
    this.path = `views/${input.viewId}/comments`;
  }
}

export interface UpdateCommentCommandInput {
  viewId: string;
  text: string;
}

export interface UpdateCommentCommandOutput extends Comment {}

export class UpdateCommentCommand
  implements ApiCommand<UpdateCommentCommandInput, UpdateCommentCommandOutput>
{
  method = 'PUT';
  path: string;

  constructor(
    public data: UpdateCommentCommandInput,
    public response?: UpdateCommentCommandOutput,
  ) {
    this.path = `views/${this.data.viewId}/comments`;
  }
}

export interface LikeCommentCommandInput {
  viewId: string;
  commentId: string;
}

export interface LikeCommentCommandOutput extends Comment {}

export class LikeCommentCommand
  implements ApiCommand<LikeCommentCommandInput, LikeCommentCommandOutput>
{
  method = 'POST';
  path: string;

  constructor(public data: LikeCommentCommandInput, public response?: LikeCommentCommandOutput) {
    this.path = `views/${this.data.viewId}/comments/${this.data.commentId}/like`;
  }
}

export interface UnlikeCommentCommandInput {
  viewId: string;
  commentId: string;
}

export interface UnlikeCommentCommandOutput extends Comment {}

export class UnlikeCommentCommand
  implements ApiCommand<UnlikeCommentCommandInput, UnlikeCommentCommandOutput>
{
  method = 'POST';
  path: string;

  constructor(
    public data: UnlikeCommentCommandInput,
    public response?: UnlikeCommentCommandOutput,
  ) {
    this.path = `views/${this.data.viewId}/comments/${this.data.commentId}/unlike`;
  }
}

export interface DeleteCommentCommandInput {
  viewId: string;
  commentId: string;
}

export class DeleteCommentCommand implements ApiCommand<DeleteCommentCommandInput, undefined> {
  method = 'DELETE';
  path: string;

  constructor(input: DeleteCommentCommandInput) {
    this.path = `views/${input.viewId}/comments/${input.commentId}`;
  }
}
