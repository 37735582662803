import loading from '~/images/animated-nessi.gif';
import './splash.scss';

export function Splash() {
  return (
    <div id='splash'>
      <img className='splash' src={loading} alt='Loading...' />
    </div>
  );
}
