import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import useLocalStorage from '~/hooks/useLocalStorage';
import { useUser } from '~/hooks/useUser';
import { ReactComponent as Arrow } from '~/images/arrow.svg';
import { ReactComponent as Diamond } from '~/images/diamond.svg';
import { ReactComponent as LochLogo } from '~/images/logo-dry.svg';
import { ReactComponent as LogoutIcon } from '~/images/logout-icon.svg';
import { ReactComponent as Mail } from '~/images/mail.svg';
import { ReactComponent as MenuToggle } from '~/images/menu-toggle.svg';
import { ReactComponent as LochNameColor } from '~/images/name-color.svg';
import { ReactComponent as LochName } from '~/images/name.svg';
// import { ReactComponent as UserGuideIcon } from '~/images/user-guide-icon.svg';
import { ReactComponent as UserIcon } from '~/images/user-icon.svg';
import { ReactComponent as VisitUs } from '~/images/visit-us.svg';
import { ProtectedRoutePath } from '~/route-path';
import MainMenu from './mainMenu';
import UserMenu from './userMenu';

import './styles.scss';

export default function Menu(): JSX.Element {
  const { user } = useUser();
  const history = useHistory();
  const location = useLocation();
  const [collapsed, setCollapsed] = useLocalStorage('collapsed', false);
  const [collapsedComments, setCollapsedComments] = useLocalStorage('commentCollapsed', false);
  const [userMenuMode, setUserMenuMode] = useState(false);

  useEffect(() => {
    const verifyPathName = (locationPathName: string) => {
      const userMenuPathNames = [
        ProtectedRoutePath.Tenant,
        ProtectedRoutePath.Profile,
        ProtectedRoutePath.TenantUsage,
      ];
      if (userMenuPathNames.includes(locationPathName)) {
        return true;
      }
      return false;
    };

    if (verifyPathName(location.pathname) && !userMenuMode) {
      setUserMenuMode(true);
    }
  }, [location.pathname, userMenuMode]);

  const name = [user?.attributes?.given_name, user?.attributes?.family_name]
    .filter(Boolean)
    .join(' ');

  const handleGoHome = () => {
    history.push('/home');
  };

  const handleToggleCollapsed = () => {
    setCollapsed((e) => !e);
    if (collapsedComments === false) {
      setCollapsedComments(true);
    }
  };

  const handleResetMenu = () => {
    setUserMenuMode((e) => false);
    history.push('/home');
  };

  const handleSetUserMenuMode = () => {
    if (!userMenuMode && location.pathname !== '/profile') {
      history.push('/profile');
      setUserMenuMode(true);
    } else if (userMenuMode) {
      history.push('/home');
      setUserMenuMode(false);
    } else {
      setUserMenuMode((e) => !e);
    }
  };

  const handleLogout = async () => {
    await Auth.signOut();
    localStorage.removeItem('currentView');
    history.push('/login');
  };

  return (
    <nav
      id='menu-navigation'
      className={`${userMenuMode ? 'theme-dark-inverse' : 'theme-dark'} ${
        collapsed ? 'collapsed' : ''
      }`}>
      <div className='header'>
        <button className='logo-container' onClick={handleGoHome}>
          <LochLogo className='logo' title='loch logo' />
          {!userMenuMode ? (
            <LochName className='name' title='loch analytics' />
          ) : (
            <LochNameColor className='name' title='loch analytics' />
          )}
        </button>
      </div>

      {collapsed ? (
        <button className='expand-button' onClick={handleToggleCollapsed}>
          <Arrow className='arrow z-10' />
          <Diamond className='toggle color-dark brightness-[.80]' />
        </button>
      ) : (
        <button className='collapse-button' onClick={handleToggleCollapsed}>
          <Arrow className='arrow z-10' />
          <MenuToggle className='toggle color-dark brightness-[.80]' />
        </button>
      )}
      {!userMenuMode ? <MainMenu collapsed={collapsed} /> : <UserMenu onGoBack={handleResetMenu} />}

      <footer className='footer'>
        <a href='https://lochanalytics.com' target='_blank' rel='noopener noreferrer'>
          <VisitUs width={20} />
          {!collapsed && <span className='single-line'>Visit Us</span>}
        </a>
        <a href='mailto:analytics@kikoda.com' target='_blank' rel='noopener noreferrer'>
          <Mail width={20} />
          {!collapsed && <span className='single-line'>Contact Us</span>}
        </a>
        {/* <a href='/assets/Loch Basic User Guide 2023.pdf' target='_blank' rel='noopener noreferrer'>
          <UserGuideIcon width={20} />
          {!collapsed && <span className='single-line'>User Guide</span>}
        </a> */}
        <button onClick={handleSetUserMenuMode}>
          <UserIcon width={20} />
          {!collapsed && (
            <span className='single-line'>{name ? `Welcome, ${name}!` : 'Welcome'}</span>
          )}
        </button>
        <button onClick={handleLogout}>
          <LogoutIcon width={20} />
          {!collapsed && <span className='single-line'>Logout</span>}
        </button>
      </footer>
    </nav>
  );
}
