import React from 'react';
import { useTableau } from '~/hooks/tableauProvider';

export function useGetTableauToken<T>(key?: T) {
  const tableauClient = useTableau();
  // Initialize token to invalid token.
  const [token, setToken] = React.useState('-');
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function load() {
      // Wait for tableauClient to load.
      if (!tableauClient) return;

      const token = await tableauClient.jwt();
      // Set null token to invalid token to clear Tableau session.
      setToken(token ?? '-');
      setIsLoading(false);
    }

    setIsLoading(true);
    load();
  }, [key, tableauClient]);

  return { token, isLoading };
}
