const LogoDryIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg version='1.1' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='512' height='512' rx='102.4' ry='102.4' fill='#72b6c6' />
    <path
      d='m205.8 127.1c1.786-4.139 5.993-6.313 9.396-4.85 3.046 1.304 4.395 5.078 3.373 8.822-0.3472-0.2568-0.7143-0.4742-1.111-0.6421-3.403-1.462-7.61 0.7113-9.396 4.85-0.1885 0.4347-0.3175 0.8693-0.4365 1.304-2.56-1.877-3.403-5.828-1.826-9.484m118.1 246.5 4.326-7.617c2.649-4.683 1.687-10.86-2.719-13.95-5.08-3.566-12-1.926-14.97 3.329l-10.35 18.24h-18.3l12.03-21.2c3.086-5.433 1.756-12.61-3.532-15.95-5.645-3.576-13.06-1.67-16.31 4.05l-18.77 33.1h-17.38l7.263-12.81c2.659-4.683 1.697-10.85-2.719-13.94-5.08-3.566-12-1.926-14.97 3.319l-13.04 23c-9.277-1.403-17.42-6.451-22.83-14.41-6.28-9.237-7.59-20.43-3.641-30.81l67.37-139.5s3.413-6.569 6.826-14.13c6.489-14.1 8.572-30.26 5.189-40.78l8.622-15.21c1.845-3.24 1.32-7.498-1.597-9.829-3.721-2.964-9.039-1.828-11.28 2.124l-5.606 9.889c-1.458-1.363-2.649-2.44-2.649-2.44-2.143-2.094-4.465-3.932-6.915-5.522l7.739-13.64c1.875-3.3 1.339-7.617-1.627-9.987-3.78-3.013-9.178-1.857-11.46 2.163l-8.721 15.38c-3.413-0.8594-6.896-1.363-10.4-1.462l1.597-2.806c1.836-3.24 0.387-7.429-3.274-8.772-2.877-1.057-6.092 0.3754-7.6 3.033l-6.042 10.65c-6.29 1.936-12.23 5.186-17.28 9.839l-18.11 19.37c-5.08 4.683-19.42 16.63-11.23 24.59 7.312 7.123 14.98 7.281 32.16 4.643 7.58-0.8397 18.82-6.154 23.79-10.17 2.282-1.837 5.705 0.168 4.931 2.983-1.399 5.038-9.882 9.207-12.94 12.74-24.6 50.93-66.47 137.7-66.61 138-12.17 30.21-5.536 65.08 18.24 88.96 15.82 15.9 37.79 24.17 60.26 24.17h150.7c1.756 0 3.195-1.432 3.175-3.181-0.3177-29.79-10.19-46.45-51.3-45.5'
      fill='#fefefe'
      fillRule='evenodd'
    />
  </svg>
);

export default LogoDryIcon;
