import React from 'react';
import { useLocation } from 'react-router-dom';
import Message from '~/components/Elements/message';

function NotFound(): JSX.Element {
  const location = useLocation();

  return (
    <Message>
      Uh oh! Where is this?
      <br />
      Resource not found: {location.pathname}
    </Message>
  );
}

export default NotFound;
